/* eslint-disable max-lines */
import React from 'react'
import {
  ChipInput,
  CodeInput,
  DateInput,
  Dropdown,
  Gender,
  LinkedIn,
  Location,
  Members,
  MobileNumber,
  Nationality,
  Newsletters,
  NumberInput,
  PendingMembers,
  PhotoUpload,
  ReadOnly,
  SocialMedia,
  TextInput,
  Website,
} from '../../components'
import { IUser } from '../../models/IUser'
import { useIntl } from 'react-intl'
import { ListItem } from '../../components/Dropdown/types'
import { IOverlayFieldProps } from './types'

const OverlayField = ({
  step,
  organisationId,
  performSecondary,
  updateFieldsData,
  editable,
}: IOverlayFieldProps) => {
  const intl = useIntl()
  if (!step) {
    return null
  }

  const changeSocialMedia = (value: any, status: any) => {
    updateFieldsData([{ type: 'LinkedIn', url: value }], status)
  }

  switch (step.field) {
    case 'readonly':
      return <ReadOnly value={step.value} />

    case 'members':
      return (
        <Members
          organisationId={organisationId ?? ''}
          editable={editable}
          name={step.name}
          value={step.value as IUser[]}
        />
      )
    case 'pendingRequests':
      return (
        <PendingMembers
          editable={editable}
          organisationId={organisationId ?? ''}
          value={
            step.value as {
              id: string
              user: IUser
              invites: any[]
              createdAt: string
            }[]
          }
        />
      )
    case 'pendingInvitations':
      return (
        <PendingMembers
          isInvitation
          organisationId={organisationId ?? ''}
          editable={editable}
          value={
            step.value as {
              id: string
              user: IUser
              invites: any[]
              createdAt: string
            }[]
          }
        />
      )
    case 'code':
      return (
        <CodeInput
          name={step.name}
          change={updateFieldsData}
          secondaryAction={() =>
            performSecondary && performSecondary(step.name || '')
          }
        />
      )

    case 'chip':
      return (
        <ChipInput
          label={step.label}
          editable={editable}
          change={updateFieldsData}
          chips={step.value}
          name={step.name}
        />
      )

    case 'gender':
      // eslint-disable-next-line
      const list = require(`../../translations/${intl.formatMessage({
        id: `${step.list}`,
      })}`)

      return (
        <Gender
          list={list}
          label={step.label}
          name={step.name}
          editable={editable}
          change={updateFieldsData}
          value={step.value}
        />
      )
    case 'nationality':
      return (
        <Nationality
          label={step.label}
          name={step.name}
          editable={editable}
          change={updateFieldsData}
          step={step}
        />
      )

    case 'dropdown':
      try {
        // eslint-disable-next-line
        const list = require(`../../translations/${intl.formatMessage({
          id: `${step.list}`,
        })}`)
        // eslint-disable-next-line
        const parsedList = list.map((item: { code: string; name: string }) => {
          const obj: ListItem = {
            key: item.code,
            value: item.name,
          }
          return obj
        })

        return (
          <Dropdown
            label={step.label}
            editable={editable}
            change={updateFieldsData}
            list={parsedList}
            name={step.name}
            // label={translate(`${step.label}`).toString()}
            value={step.value}
            hasDefaultOption={false}
          />
        )
      } catch (e) {
        return null
      }
    case 'photo-upload':
      return (
        <PhotoUpload
          label={step.label}
          name={step.name}
          editable={editable}
          value={step.value}
          change={updateFieldsData}
        />
      )
    case 'dob':
      return (
        <DateInput
          label={step.label}
          name={step.name}
          editable={editable}
          value={step.value}
          change={updateFieldsData}
        />
      )
    case 'socialMedia':
      return (
        <SocialMedia
          label={step.label}
          editable={editable}
          name={step.name}
          value={step.value as any[]}
          change={updateFieldsData}
        />
      )
    case 'linkedIn':
      return (
        <LinkedIn
          label={intl.formatMessage({ id: step.label })}
          editable={editable}
          name={step.name}
          type={step.type || step.name}
          value={step.value && step.value[0] && (step.value[0].url as string)}
          change={changeSocialMedia}
        />
      )
    case 'textarea':
      return (
        <TextInput
          label={intl.formatMessage({ id: step.label })}
          key={step.name}
          editable={editable}
          name={step.name}
          maxLength={step.maxLength}
          type='textarea'
          value={step.value as string}
          change={updateFieldsData}
        />
      )
    case 'website':
      return (
        <Website
          label={intl.formatMessage({ id: step.label })}
          editable={editable}
          name={step.name}
          type={step.type || step.name}
          value={step.value as string}
          change={updateFieldsData}
        />
      )
    case 'text':
      return (
        <TextInput
          autoFocus
          label={intl.formatMessage({ id: step.label })}
          fluid
          key={step.name}
          editable={editable}
          name={step.name}
          type={step.type || step.name}
          value={step.value as string}
          change={updateFieldsData}
        />
      )
    case 'location':
      return (
        <Location
          label={step.label}
          name={step.name}
          change={updateFieldsData}
          editable={editable}
          value={step.value as string}
        />
      )
    case 'mobileNumber':
      return (
        <MobileNumber
          label={step.label}
          editable={editable}
          name={step.name}
          type={step.type || step.name}
          value={step.value as string}
          change={updateFieldsData}
        />
      )
    case 'number':
      return (
        <NumberInput
          autoFocus
          label={intl.formatMessage({ id: step.label })}
          key={step.name}
          editable={editable}
          name={step.name}
          type={step.type || step.name}
          value={step.value as string}
          change={updateFieldsData}
        />
      )
    case 'newsletters':
      return <Newsletters />

    default:
      return (
        <TextInput
          autoFocus
          label={intl.formatMessage({ id: step.label })}
          fluid
          key={step.name}
          editable={editable}
          name={step.name}
          type={step.type || step.name}
          value={step.value as string}
          change={updateFieldsData}
        />
      )
  }
}

export default OverlayField
