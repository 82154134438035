import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { parse } from 'query-string'
import { apiService } from '../../service/api/apiService'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { AppContext } from '../../utils/ContextWrapper'
import { useAuthorize } from '../authorize/useAuthorize'
import { Container } from './styles'
import { setLoggedInCookie, useCookie } from '../../utils/cookies'
import { decorateUrlWithLanguagePath } from "../../routing/utils";

const { REACT_APP_WELCOME_URL, REACT_APP_LOGIN_URL } = process.env

const VerifyEmailPage = () => {
  const { search } = useLocation()
  const [user] = useLocalStorage<IUser>('user')
  const [token, setToken] = useCookie('token')
  const [redirect, setRedirect] = useState('')
  const [, setAppState] = useContext(AppContext)
  const { isAuthorizeFlow, authorize } = useAuthorize()

  useEffect(() => {
    if (user && token) {
      setLoggedInCookie()
      if (isAuthorizeFlow) {
        setAppState((state) => ({
          ...state,
          authorizeData: { ...state.authorizeData, isRegister: true },
        }))
        authorize()
      } else {
        setRedirect(decorateUrlWithLanguagePath(REACT_APP_WELCOME_URL || '/welcome'))
        window.location.reload()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, authorize, isAuthorizeFlow, setAppState])

  useEffect(() => {
    const { token: verificationToken, app_id: appId, next } = parse(search)

    if (verificationToken && !Array.isArray(verificationToken)) {
      apiService.auth
        .verifyEmail(verificationToken)
        .then(({ user, token }) => {
          setAppState((state) => ({
            ...state,
            authorizeData: {
              appId: appId as string,
              next: next as string,
              resetToken: false,
            },
          }))

          writeStorage('user', user)
          setToken(token)
        })
        .catch((error) => {
          console.error(error)
          setRedirect(decorateUrlWithLanguagePath(REACT_APP_LOGIN_URL || '/login'))
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setAppState])

  if (redirect) return <Redirect to={redirect} />

  return (
    <Container>
      <h1>
        <FormattedMessage id='auth.verifyEmail.heading' />
      </h1>
    </Container>
  )
}

export default VerifyEmailPage
