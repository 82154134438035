import React from 'react'
import styled from 'styled-components'
import { customStyles } from '../Dropdown/styles'

export const dateStyles = {
  ...customStyles,
  control: (provided: React.CSSProperties, { isSelected }: any) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid var(--primary)',
    boxShadow: isSelected ? '0 0 0 1px var(--primary)' : '',
    height: 50,
    background: 'var(--background)',
  }),
}

export const StyledWrapper = styled.div`
  width: 100px;
  margin: 0 10px;
  .invalid > div {
    border: 1px solid var(--red);
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  text-align: center;
  > .error-validation-message {
    width: 64%;
  }
`
