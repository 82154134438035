import { apiService } from '../../service/api/apiService'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { openToast } from '../../components/ToastMessage/ToastService'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { useIntl } from 'react-intl'
import { AppContext, AuthDataContext } from '../../utils/ContextWrapper'
import { decorateUrlWithLanguagePath } from "../../routing/utils";
import Cookies from "js-cookie";
import { defaultCookieOptions } from "../../utils/cookies";
import UserAuthClass, { SESSION_TOKEN_KEY } from "../../utils/UserAuthClass";

const { REACT_APP_LOGIN_URL } = process.env

const removeParameter = (url: string, name: string ) => {
  const [ head, tail ] = url.split( '?' );
  return `${head}?${tail?.split( '&' ).filter(p => !p.startsWith(`${name}=`)).join( '&' ) || ''}`;
}

export const useAuthorize = () => {
  const history = useHistory()
  const intl = useIntl()
  const [{ authorizeData }, setAppState] = useContext(AppContext)
  const [{ email, password }] = useContext(AuthDataContext)
  const [user] = useLocalStorage<IUser>('user')
  const { appId, next } = authorizeData
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    if (redirect) {
      history.push(redirect)
    }
  }, [redirect, history])

  const authorize = useCallback(async () => {
    const stubbedAppId = appId || 'programs'
    const userStorage = JSON.parse(localStorage.getItem('user') as string)
    if (stubbedAppId) {
      const data = await apiService.user.get(userStorage?.id || '')
      await apiService.auth
        .getMandatoryProfileFields(stubbedAppId)
        .then((mandatoryFields) => {
          setAppState((state) => ({ ...state, mandatoryFields }))
          const missingFields =
            data &&
            mandatoryFields.filter((field: string) =>
              Array.isArray(data[field])
                ? data[field].length === 0
                : !data[field],
            )
          if (missingFields.length && appId) {
            setAppState((state) => ({
              ...state,
              authorizeData: {
                ...state.authorizeData,
                appId,
              },
              missingFields,
            }))
            setRedirect(decorateUrlWithLanguagePath('/mandatory-fields'))
          } else {
            // TODO: Check if we need separate token for programs only DFF-863
            apiService.auth.authorize(stubbedAppId, next ?? '').then((url: string) => {
              const urlObject = new URL(url);
              const params = new URLSearchParams(urlObject.search)
              const token = params.get(SESSION_TOKEN_KEY)
              let newUrl = url;
              if (token) {
                Cookies.set(SESSION_TOKEN_KEY, token, defaultCookieOptions)
                newUrl = removeParameter(url, SESSION_TOKEN_KEY);
              }
              window.location.assign(next || newUrl)
              // Commented for local developments (dev or prod url from DB comes)
              // window.location.assign(next || newUrl.replace('https://dev.programs.dffdev.site', 'http://fid-frontend.dff.local:3001'))
            })
          }
        })
        .catch(({ response }) => {
          const showGenericFailureToast = () => {
            openToast({
              message: intl
                .formatMessage({ id: 'errors.genericFail' })
                .toString(),
              variant: 'failure',
            })
          }
          setAppState((state) => ({
            ...state,
            authorizeData: { appId: '', next: '', resetToken: false },
          }))
          if (response?.status === 404) {
            setRedirect('/')
            openToast({
              message: intl
                .formatMessage({ id: 'errors.wrongAppId' })
                .toString(),
              variant: 'failure',
            })
          } else if (response?.status === 401) {
            UserAuthClass.setLogoutUserStatus();
            setRedirect(decorateUrlWithLanguagePath(REACT_APP_LOGIN_URL || '/login'))
            openToast({
              message: intl
                .formatMessage({ id: 'errors.tokenExpired' })
                .toString(),
              variant: 'failure',
            })
          } else {
            setRedirect('/')
            showGenericFailureToast()
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAppState, user, intl, appId, next, email, password])

  return {
    authorize,
    isAuthorizeFlow: !!appId,
  }
}
