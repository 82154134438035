import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CodeInput } from '../../components'
import { openToast } from '../../components/ToastMessage/ToastService'
import { apiService } from '../../service/api/apiService'
import { useAuthorize } from '../authorize/useAuthorize'
import {
  AuthDataContext,
  UrlContext,
  TopLoadingBarContext,
} from '../../utils/ContextWrapper'
import { useLocation, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import { Link, LinkButton, LinkContainer } from './styles'
import { decorateUrlWithLanguagePath } from "../../routing/utils";
import { getProgramsSessionToken } from "./utils";
import UserAuthClass from "../../utils/UserAuthClass";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  @media only screen and (max-width: 480px) {
    width: 350px;
  }
`

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.lg};
  margin-bottom: 4rem;
  max-width: 40rem;
  text-align: center;
`

const InputCodeContainer = styled.div`
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`

const { REACT_APP_DASHBOARD_URL, REACT_APP_RESET_TFA_URL } = process.env

const TfaLogin = () => {
  const intl = useIntl()
  const location = useLocation()
  const { oauth2_authorize_url: oauth2Url } = queryString.parse(location.search)
  const { isAuthorizeFlow, authorize } = useAuthorize()
  const [redirect, setRedirect] = useState('')
  const [url] = useContext(UrlContext)
  const [{ email, password }, setLoginData] = useContext(AuthDataContext)
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)

  const redirectToResetTFA = () => {
    setLoginData({ email, password })
    setRedirect(decorateUrlWithLanguagePath(REACT_APP_RESET_TFA_URL || '/reset-tfa'))
  }

  const handleTwoFactor = async (code: string, status: boolean) => {
    if (email && password && status) {
      setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
      try {
        const { token, user } = await apiService.auth.login(
          email,
          password,
          code,
        )
        UserAuthClass.setLoggedUserStatus(user, token)
        setLoginData({ email, password })
        // TODO: Check if we need to load programs session token DFF-863
        await getProgramsSessionToken()
        if (isAuthorizeFlow) {
          authorize()
        } else {
          if (oauth2Url) {
            const oauthRedirectUrl =
              (process.env.REACT_APP_BACKEND_AUTH_URL ?? '') + oauth2Url
            window.location.href = oauthRedirectUrl
          } else {
            setRedirect(decorateUrlWithLanguagePath(url ?? (REACT_APP_DASHBOARD_URL || '/dashboard')))
            // TMP hack for WP side for pull cookies from the browser
            window.location.reload()
          }
        }
      } catch (e) {
        if (e.status === 499) {
          openToast({
            message: intl.formatMessage({ id: 'errors.invalid2FCode' }),
            variant: 'failure',
          })
        } else {
          openToast({
            message: intl.formatMessage({ id: 'errors.genericFail' }),
            variant: 'failure',
          })
        }
      } finally {
        setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
      }
    }
  }
  if (redirect) return <Redirect to={redirect} />

  return (
    <Container>
      <Title>
        <FormattedMessage id='settings.twofactor.loginEnterCode' />
      </Title>
      <InputCodeContainer>
        <CodeInput name='twoFactorVerify' length={6} change={handleTwoFactor} />
      </InputCodeContainer>
      <LinkContainer>
        <LinkButton>
          <FormattedMessage id='auth.labels.backupCodeStart' />
          <Link onClick={redirectToResetTFA}>
            <FormattedMessage id='auth.labels.backupCodeLink' />
          </Link>
          <FormattedMessage id='auth.labels.backupCodeEnd' />
        </LinkButton>
      </LinkContainer>
    </Container>
  )
}

export default TfaLogin
