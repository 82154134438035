import styled from "styled-components";
import { ErrorMessageWrapperProps } from "./types";

export const ErrorMessageWrapper = styled.div<ErrorMessageWrapperProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  color: var(--red);
  font-size: 11px;
  line-height: 16px;
  padding-top: 5px;
  direction: ${({isRtl}) => isRtl ? 'rtl' : 'ltr'};
`
