import React, { useState } from 'react'
import { IconLabelBtn, SectionHeader, FormContainer } from '../../../components'
import { ISettingsObj } from '../types'
import Options from '../../../assets/data/settingsOptions.json'
import TwoFactorAuth from './TwoFactorAuth/TwoFactorAuth'
import { Section, SectionItem } from '../../dashboard/Section'
import Devices from '../devices/Devices'
import { useIntl } from 'react-intl'
import { TutorialSteps } from '../../../components/Tutorial/tutorialTypes'
import DeleteAccount from "../DeleteAccount/DeleteAccount";

const SettingsMenu = () => {
  const intl = useIntl()
  const [genericOverlayVisible, setGenericOverlayVisible] = useState(false)
  const [currentStep, setCurrentStep] = useState<ISettingsObj>()
  const [deleteOverlayVisible, setDeleteOverlayVisible] = useState(false)
  const [tfaOverlayVisible, setTfaOverlayVisible] = useState(false)

  const handleClick = (step: ISettingsObj) => {
    if (step.name === 'twoFactorAuthentication') {
      setTfaOverlayVisible(true)
    } else if (step.name === 'deleteAccount') {
      setDeleteOverlayVisible(true)
    } else {
      step.value = ''
    }

    if (
      step.name !== 'twoFactorAuthentication' &&
      step.name !== 'deleteAccount' &&
      step.name !== 'tutorial'
    ) {
      setGenericOverlayVisible(true)
      setCurrentStep(step)
    }
  }

  const getOverlayContent = (): React.ReactElement => {
    if (currentStep?.name === 'devices') {
      return <Devices />
    }

    return <></>
  }

  const twoFactorOverlayClose = () => setTfaOverlayVisible(false)
  const getSectionClass = (name?: string) => {
    const tutorialClass = name === 'tutorial' ? TutorialSteps.HOWTO : ''
    const visibleClass = name === 'deleteAccount' ? 'mobile-hidden' : ''
    return `${tutorialClass} ${visibleClass}`;
  }

  return (
    <>
      <SectionHeader
        title={intl.formatMessage({ id: 'settings.header' })}
        hideMobile
        id={TutorialSteps.SETTINGS}
      />
      <Section>
        {Options?.map((settings: ISettingsObj, i) => {
          return (
            <SectionItem
              key={settings.name}
              className={getSectionClass(settings.name)}
              id={settings.name === 'tutorial' ? TutorialSteps.HOWTO : ''}
            >
              <IconLabelBtn
                completed
                iconFileName={settings.iconName}
                label={intl.formatMessage({ id: settings.label || '' })}
                onClick={() => handleClick(settings)}
              />
            </SectionItem>
          )
        })}
      </Section>

      {currentStep && (
        <FormContainer
          isOpen={genericOverlayVisible}
          title={intl.formatMessage({
            id: currentStep.label,
            defaultMessage: '',
          })}
          isValid
          onClose={() => setGenericOverlayVisible(false)}
          currentIndex={0}
          stepLabels={['']}
          hasActionButtons={false}
        >
          {getOverlayContent()}
        </FormContainer>
      )}

      <TwoFactorAuth open={tfaOverlayVisible} onClose={twoFactorOverlayClose} />
      <DeleteAccount
        open={deleteOverlayVisible}
        handleClose={() => setDeleteOverlayVisible(false)}
      />
    </>
  )
}

export default React.memo(SettingsMenu)
