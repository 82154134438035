import React, { useContext, useState } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { ActionMeta, OptionsType, ValueType } from 'react-select'
import { useAutoInputFocus } from '../../utils/hooks/useAutoInputFocus'
import debounce from 'lodash.debounce'
import { apiService } from '../../service/api/apiService'
import { ArrowNavigationContext } from '../../utils/ContextWrapper'
import { FormattedMessage, useIntl } from 'react-intl'
import { Container, locationStyles } from './styles'
import { ILocationProps } from './types'

const OrganisationSearch = ({
  change,
  value,
  onCreateOption,
  containerRef
}: ILocationProps) => {
  const intl = useIntl()
  const [, setArrowNavigationEnabled] = useContext(ArrowNavigationContext)
  const [isFocused, setIsFocused] = useState(false)

  const isRtl = intl.locale === 'ar'
  const [currentVal, setCurrentVal] = useState<{
    value: string
    label: string
  } | null>(null)

  const getOrganisationNames = async (
    searchString: string,
    callback: (options: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    try {
      const { data } = await apiService.organisation.names(searchString)
      callback(data.map((value) => ({ value: value.id, label: value.name })))
    } catch (e) {
      callback([])
    }
  }

  const debouncedOrganisationNames = debounce(getOrganisationNames, 400)
  const loadOptions = (
    searchString: string,
    callback: (options: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    debouncedOrganisationNames(searchString, callback)
  }

  const handleChange = (
    value: { value: string; label: string },
    action: ActionMeta<any>,
  ) => {
    setCurrentVal(value)
    if (value) {
      change(value.label, value.value)
    }
    if (action?.action === 'create-option') {
      onCreateOption(value.value)
    }
  }

  useAutoInputFocus(!!value, isFocused, true)

  const handleFocus = () => {
    setIsFocused(true)
    setArrowNavigationEnabled(false)
  }

  const handleBlur = () => {
    setIsFocused(false)
    setArrowNavigationEnabled(true)
  }

  return (
    <Container>
      <AsyncCreatableSelect
        className='focusClass'
        isClearable
        onFocus={handleFocus}
        onBlur={handleBlur}
        isRtl={isRtl}
        autoFocus={value === ''}
        placeholder={
          <FormattedMessage id='components.location.typePlaceholder' />
        }
        value={currentVal}
        menuPortalTarget={containerRef?.current}
        menuPosition='fixed'
        styles={locationStyles}
        cacheOptions
        onChange={
          handleChange as (
            value: ValueType<{ value: string; label: string }>,
          ) => void
        }
        loadOptions={loadOptions}
        defaultOptions
      />
    </Container>
  )
}

export { OrganisationSearch }
