/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react'
import validate from '../../utils/fieldsValidator'
import { CustomInput as Input } from './Input'
import debounce from 'lodash.debounce'
import { useIntl } from 'react-intl'
import { ITextInputProps } from './types'

const NumberInput = ({
  label,
  value,
  disableDebounce,
  change,
  type = 'number',
  placeholder,
  maxLength = 200,
  editable = true,
  disableRtl = false,
  showStatusHints = false,
  valid,
  disabled,
  status,
  ...props
}: ITextInputProps) => {
  const intl = useIntl()
  const [inputValue, setInputValue] = useState(value)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(debounce(change, 400), [])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const re = /^[0-9\b]+$/
    e.persist()
    // if value is not blank, then test the regex

    const { value } = e.target
    if (value === '' || re.test(value)) {
      setInputValue(value)
      const valid = validate({ value, type: 'number' }) === 'success'
      disableDebounce ? change(value, valid) : handler(value, valid)
    }
  }

  const rtl = !disableRtl && intl.locale === 'ar'
  return (
    <Input
      type='tel'
      fluid
      style={{ minWidth: '80px', maxWidth: '100px', margin: 'auto' }}
      readonly={!editable}
      rtl={rtl}
      status={validate({ type: 'number', value: inputValue?.toString() ?? '' })}
      label={label}
      value={inputValue}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={
        handleChange as (e: React.ChangeEvent<HTMLInputElement>) => void
      }
      {...props}
    />
  )
}

export { NumberInput }
export default NumberInput
