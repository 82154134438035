import React, { useContext } from 'react'
import { FormContainer } from '../../../components'
import { IProfileObject } from '../../../models/IProfileField'
import { apiService } from '../../../service/api/apiService'
import useSWR from 'swr'
import OverlayFieldWrapper from './OverlayFieldWrapper'
import { openToast } from '../../../components/ToastMessage/ToastService'
import { useAuthorize } from '../../authorize/useAuthorize'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { useIntl } from 'react-intl'
import { AppContext } from '../../../utils/ContextWrapper'
import { TRAVERSE } from './constants'
import { IFormWrapperProps } from './types'

const FormWrapper = ({
  isOverlayOpen,
  setIsOverlayOpen,
  currentIndex,
  setCurrentIndex,
  steps,
}: IFormWrapperProps) => {
  const { isAuthorizeFlow } = useAuthorize()
  const intl = useIntl()
  const [{ mandatoryFields }] = useContext(AppContext)
  const hasMandatoryFields =
    steps &&
    mandatoryFields.every(
      (field: string) => {
        const step = steps.find((step) => step.name === 'field')
        return (
          !!step?.value &&
          !(Array.isArray(step.value) && step.value.length === 0)
        )
      },
      // tags when not filled out = []
    )
  const [user] = useLocalStorage<IUser>('user')
  const { data, mutate } = useSWR(
    !!user ? ['user', user.id] : null,
    (key: string, userId: string) => apiService.user.get(userId),
  )

  const currentStep = steps[currentIndex]

  const resendVerifyEmail = async (email: string) => {
    try {
      await apiService.auth.resendVerifyEmail(email)
      openToast({
        message: intl.formatMessage({
          id: 'auth.updateEmail.resendSuccess',
        }),
        variant: 'success',
      })
    } catch (e) {
      openToast({
        message: intl.formatMessage({ id: 'errors.genericFail' }),
        variant: 'failure',
      })
    }
  }

  /* Secondary action to be performed here for data points */
  const performSecondary = (datapoint?: string) => {
    switch (datapoint) {
      case 'verification': {
        const email = steps[0].value
        if (email) resendVerifyEmail(email)
      }
    }
  }

  const isValid = (step: IProfileObject) => {
    // make sure form is not closable if it is the last step and we are in mandatory fields flow without all fields being filled out
    if (currentIndex === steps.length) {
      if (isAuthorizeFlow && !hasMandatoryFields) {
        return false
      }
    }
    return step.skipValidity || !!step.value
  }

  const updateUserData = async (step: IProfileObject) => {
    if (
      data &&
      user &&
      step.name &&
      step.field !== 'newsletters' &&
      step.field !== 'photo-upload'
    ) {
      const { name, value } = step
      mutate({ ...data, [name]: value }, false)
    }
  }

  const validateAndSave = () => {
    if (!currentStep.path && !currentStep.action && isValid(currentStep)) {
      updateUserData(currentStep)
    }
  }

  const onUpdateField = async () => {
    const { name, value } = steps[currentIndex]
    if (user && name && value) {
      await apiService.user.update(user.id, { [name]: value })
    }
  }

  const stepsTraversing = async (direction: TRAVERSE, close?: boolean) => {
    if (direction === TRAVERSE.FORWARD) {
      if (isValid(currentStep) && !close) {
        updateUserData(currentStep)
      }
      if (!close && isValid(currentStep)) {
        setCurrentIndex((currentIndex) => currentIndex + 1)
      } else {
        setIsOverlayOpen(false)
      }
    } else if (direction === TRAVERSE.REVERSE) {
      setCurrentIndex((currentIndex) => currentIndex - 1)
    }
  }

  const onClose = () => {
    validateAndSave()
    setIsOverlayOpen(false)
  }

  if (!currentStep) {
    return null
  }

  return (
    <FormContainer
      enableProgressBar
      progressType='profile'
      isOpen={isOverlayOpen}
      title={intl.formatMessage({ id: `${currentStep.label}` }) || ''}
      onNextStep={(step, close?: boolean) =>
        stepsTraversing(TRAVERSE.FORWARD, close)
      }
      onPrevStep={() => stepsTraversing(TRAVERSE.REVERSE)}
      setCurrentIndex={setCurrentIndex}
      isValid={isValid(currentStep)}
      stepLabels={steps.map((step) => step.label || '')}
      onClose={!isAuthorizeFlow || hasMandatoryFields ? onClose : undefined}
      currentIndex={currentIndex}
      steps={steps}
      hasActionButtons={currentStep.name !== 'verification'}
      onStepSubmit={onUpdateField}
    >
      <OverlayFieldWrapper
        editable
        updateUserData={updateUserData}
        step={currentStep}
        performSecondary={performSecondary}
      />
    </FormContainer>
  )
}

export default FormWrapper
