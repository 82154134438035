import styled from 'styled-components'
import backgroundImage from '../../assets/images/auth_background_image.jpg'
import { StyleTypeRTL } from './types'
import { getItemColor } from './utils'
import { lazy } from 'react'
import { Link } from 'react-router-dom'

export const FormWrapper = styled.div`
  scroll-behavior: unset;
  background-color: var(--background);
  padding: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.xl}`};

  @media only screen and (max-width: 480px) {
    padding: ${({ theme }) => theme.spacing.lg};
    width: calc(90vw - 48px);
    height: 80%;
    max-height: none;
    min-height: 46rem;
    max-width: none;
  }
  margin: ${({ theme }) => theme.spacing.lg} 0;
  min-width: 300px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
`
export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 75px);
  @media (max-height: 520px) {
    height: min-content;
  }
  display: flex;
  background-image: url(${backgroundImage});
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LinksContainer = styled.div``

export const Checkbox = styled.input<StyleTypeRTL>`
  margin-top: ${({ theme }) => theme.spacing.md};
  margin-left: ${({ isRtl }) => (isRtl ? '0.5rem' : '0')};
  margin-right: ${({ isRtl }) => (isRtl ? '0' : '0.5rem')};
  width: 2rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
`

export const TermsLink = styled.a`
  border: none;
  background: transparent;

  padding: 0 0.3rem;
  font-size: $font-mini;
  cursor: pointer;
  font-weight: bold;
  color: var(--primary);
`

export const RegisterProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing.lg} 0 ${theme.spacing.md}`};
  align-self: center;
  bottom: ${({ theme }) => theme.spacing.lg};
`

export const Circle = styled.div<{ done: boolean }>`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background: ${({ done }) => getItemColor(done)};
`

export const ProgressLine = styled.div<{ done: boolean }>`
  height: 3px;
  background: ${({ done }) => getItemColor(done)};
  width: 55px;
`

export const Password = lazy(() => import('../TextInput/Password'))
export const LoginRegisterContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  @media only screen and (max-width: 480px) {
    padding: 0;
    margin: 0;
  }
`

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const LoginLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.sm}`};
  @media only screen and (max-width: 480px) {
    margin: 0;
  }
  align-self: flex-start;
`

export const CustomLink = styled(Link)<{ active?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: bold;
  text-decoration: none;
  text-transform: capitalize;
  align-self: flex-start;
  margin-right: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  border-bottom: ${({ active }) =>
    active ? '1.5px solid var(--primary)' : ''};
  color: ${({ active }) => (active ? 'var(--primary)' : 'var(--grey)')};
`

export const PlatformContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
`

export const FutureID = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: normal;
  margin: ${({ theme }) => theme.spacing.md} 0;
  text-transform: uppercase;
`

export const ForgotPassword = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: var(--primary);
  font-weight: bold;
  align-self: flex-end;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
