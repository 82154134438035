import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FilledButton } from '../FilledButton/FilledButton'
import useSWR from 'swr'
import { apiService } from '../../service/api/apiService'
import { Description, Header, Separator, TextWrapper, Wrapper } from './styles'
import { INewsletterProps } from './types'

const Newsletter = ({ newsletter }: INewsletterProps) => {
  const intl = useIntl()
  const { data, mutate } = useSWR('newsletters', (key: string) =>
    apiService.newsletters.getMany(),
  )

  const handleSubscribeClick = async () => {
    // will include the condition whether one is already subscribed or not.
    // eslint-disable-next-line
    if (newsletter && newsletter.subscribed) {
      data &&
        mutate(
          {
            ...data,
            data: data.data.map((n) =>
              n.id === newsletter.id ? { ...n, subscribed: false } : n,
            ),
          },
          false,
        )
      await apiService.newsletters.unsubscribe(newsletter?.id ?? '')
    } else {
      data &&
        mutate(
          {
            ...data,
            data: data.data.map((n) =>
              n.id === newsletter.id ? { ...n, subscribed: true } : n,
            ),
          },
          false,
        )

      await apiService.newsletters.subscribe(newsletter?.id ?? '')
    }
  }

  const isArabicLocale = intl.locale === 'ar';

  const label = newsletter
    ? isArabicLocale
      ? newsletter.name.ar
      : newsletter.name.en
    : ''
  const description = newsletter
    ? isArabicLocale
      ? newsletter.description?.ar ?? ''
      : newsletter.description?.en ?? ''
    : ''

  return (
    <>
      <Wrapper>
        <TextWrapper rtl={isArabicLocale}>
          <Header>{label}</Header>
          <Description>{description}</Description>
        </TextWrapper>
        <FilledButton type='button' onClick={handleSubscribeClick}>
          <FormattedMessage
            id={
              newsletter?.subscribed
                ? 'components.newsletterOverlay.unsubscribe'
                : 'components.newsletterOverlay.subscribe'
            }
          />
        </FilledButton>
      </Wrapper>
      <Separator />
    </>
  )
}

export default Newsletter
