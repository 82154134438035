import React, { cloneElement, RefObject, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { OverlayScreen } from '..'
import { ProgressFields } from '../ProgressBar/ProgressFields'
import { Container, Content, StyledButton, Title } from './styles'
import { IProps } from './types'

const FormContainer = ({
  title,
  enableProgressBar = false,
  onNextStep,
  onPrevStep,
  isValid,
  children,
  stepLabels = [],
  isOpen,
  onClose,
  currentIndex,
  setCurrentIndex,
  steps = [],
  hasActionButtons = true,
  hasPreviousStep = false,
  showNextButton = true,
  progressType,
  onStepSubmit,
}: IProps) => {

  const containerRef = useRef<RefObject<HTMLDivElement>>(null)
  const clonedChildren = cloneElement(children, {containerRef})

  const disabled = !isValid

  const hasPrevStep = hasPreviousStep && currentIndex > 0
  const hasNextStep = currentIndex < stepLabels.length - 1
  const prevStep = hasPrevStep ? stepLabels[currentIndex - 1] : undefined
  const nextStep = hasNextStep ? stepLabels[currentIndex + 1] : undefined

  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (prevStep && onPrevStep) {
      onPrevStep(prevStep)
    }
  }

  const handleNext = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e?.preventDefault()

      if(!disabled) {
        onStepSubmit && onStepSubmit()
        if(onNextStep) {
          if (nextStep) {
            onNextStep(nextStep)
          } else if (!nextStep) {
            onNextStep(null, true)
          }
        }
      }
    },
    [disabled, nextStep, onNextStep, onStepSubmit],
  )

  return (
    <OverlayScreen isOpen={isOpen} onClose={onClose} ref={containerRef}>
      <>
        <Container
          hasProgressBar={enableProgressBar}
          onSubmit={handleNext}
          singleItem={!hasActionButtons}
        >
          <Title>{title}</Title>
          <Content>{clonedChildren}</Content>
          {hasActionButtons && (
            <>
              {hasPrevStep && (
                <StyledButton onClick={handlePrev} disabled={disabled}>
                  <FormattedMessage id='common.prev' />
                </StyledButton>
              )}
              {showNextButton && (
                <StyledButton type='submit' disabled={disabled}>
                  <FormattedMessage id='common.next' />
                </StyledButton>
              )}
            </>
          )}
        </Container>
        {enableProgressBar && setCurrentIndex && (
          <ProgressFields
            steps={steps}
            setCurrentIndex={setCurrentIndex}
            type={progressType}
            currentStepIndex={currentIndex}
          />
        )}
      </>

    </OverlayScreen>
  )
}

export { FormContainer }
