import React from 'react'
import { useLocation } from 'react-router-dom'
import LoginPage from '../pages/login/Login'
import VerifyEmailPage from '../pages/verify-email/VerifyEmail'
import Authorize from '../pages/authorize/Authorize'
import Dashboard from '../pages/dashboard/Dashboard'
import NewPasswordPage from '../pages/new-password/NewPassword'
import { UpdateEmail } from '../components/UpdateEmail/UpdateEmail'
import ResetPasswordPage from '../pages/reset-password/ResetPassword'
import RegisterPage from '../pages/register/Register'
import { NoAuthRoute } from './NoAuthRoute'
import { AuthRoute } from './AuthRoute'
import UpdatePasswordPage from '../pages/update-password/UpdatePassword'
import MandatoryFiedls from '../pages/mandatoryFields/MandatoryFields'
import { URLConfigType } from './types'
import VerificationCodePage from "../pages/verification-code/VerificationCodePage";
import BackupCode from "../pages/backup-codes/BackupCode";

const {
  REACT_APP_LOGIN_URL,
  REACT_APP_EMAIL_VERIFY_URL,
  REACT_APP_AUTHORIZE_URL,
  REACT_APP_PASSWORD_RESET_URL,
  REACT_APP_EMAIL_UPDATE_URL,
  REACT_APP_VERIFICATION_CODE_URL,
  REACT_APP_RESET_PASSWORD_URL,
  REACT_APP_RESET_TFA_URL,
  REACT_APP_REGISTER_URL,
  REACT_APP_PASSWORD_UPDATE_URL,
  REACT_APP_DASHBOARD_URL,
  REACT_APP_WELCOME_URL,
} = process.env

const urlConfig: URLConfigType = {
  authorize: {
    url: REACT_APP_AUTHORIZE_URL || '/authorize',
    component: <Authorize />,
  },
  login: {
    url: REACT_APP_LOGIN_URL || '/login',
    component: (
      <NoAuthRoute>
        <LoginPage />
      </NoAuthRoute>
    ),
  },
  emailVerify: {
    url: REACT_APP_EMAIL_VERIFY_URL || '/emailverify',
    component: (
      <NoAuthRoute>
        <VerifyEmailPage />
      </NoAuthRoute>
    ),
  },
  mandatoryFields: {
    url: '/mandatory-fields',
    component: (
      <AuthRoute>
        <MandatoryFiedls />
      </AuthRoute>
    ),
  },
  passwordreset: {
    url: REACT_APP_PASSWORD_RESET_URL || '/passwordreset',
    component: (
      <NoAuthRoute>
        <NewPasswordPage />
      </NoAuthRoute>
    ),
  },
  updatepassword: {
    url: REACT_APP_PASSWORD_UPDATE_URL || '/updatepassword',
    component: (
      <AuthRoute>
        <UpdatePasswordPage />
      </AuthRoute>
    ),
  },
  updatemail: {
    url: REACT_APP_EMAIL_UPDATE_URL || '/updatemail',
    component: (
      <AuthRoute>
        <UpdateEmail />
      </AuthRoute>
    ),
  },
  verificationCode: {
    url: REACT_APP_VERIFICATION_CODE_URL || '/verification-code',
    component: (
      <NoAuthRoute>
        <VerificationCodePage redirect />
      </NoAuthRoute>
    ),
  },
  resetPassword: {
    url: REACT_APP_RESET_PASSWORD_URL || '/reset-password',
    component: (
      <NoAuthRoute>
        <ResetPasswordPage />
      </NoAuthRoute>
    ),
  },
  resetTfa: {
    url: REACT_APP_RESET_TFA_URL || '/reset-tfa',
    component: (
      <NoAuthRoute>
        <BackupCode />
      </NoAuthRoute>
    ),
  },
  register: {
    url: REACT_APP_REGISTER_URL || '/register',
    component: (
      <NoAuthRoute>
        <RegisterPage />
      </NoAuthRoute>
    ),
  },
  dashboard: {
    url: REACT_APP_DASHBOARD_URL || '/dashboard',
    component: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
  },
  welcome: {
    url: REACT_APP_WELCOME_URL || '/welcome',
    component: (
      <AuthRoute>
        <Dashboard isWelcome />
      </AuthRoute>
    ),
  },
}

const CustomRouting = () => {
  const { pathname } = useLocation()
  const isArabicUrl = pathname.includes('ar')
  const pathnameWithoutLanguage = isArabicUrl ? pathname.replace('/ar', '') : pathname
  switch (pathnameWithoutLanguage) {
    case urlConfig.authorize.url: {
      return urlConfig.authorize.component
    }
    case urlConfig.login.url: {
      return urlConfig.login.component
    }
    case urlConfig.mandatoryFields.url: {
      return urlConfig.mandatoryFields.component
    }
    case urlConfig.emailVerify.url: {
      return urlConfig.emailVerify.component
    }
    case urlConfig.updatemail.url: {
      return urlConfig.updatemail.component
    }
    case urlConfig.verificationCode.url: {
      return urlConfig.verificationCode.component
    }
    case urlConfig.updatepassword.url: {
      return urlConfig.updatepassword.component
    }
    case urlConfig.passwordreset.url: {
      return urlConfig.passwordreset.component
    }
    case urlConfig.resetPassword.url: {
      return urlConfig.resetPassword.component
    }
    case urlConfig.resetTfa.url: {
      return urlConfig.resetTfa.component
    }
    case urlConfig.register.url: {
      return urlConfig.register.component
    }
    case urlConfig.dashboard.url: {
      return urlConfig.dashboard.component
    }
    case urlConfig.welcome.url: {
      return urlConfig.welcome.component
    }
    default: {
      return <LoginPage />
    }
  }
}

export default CustomRouting
