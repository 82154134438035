import { useLocation } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../models/IUser'
import { useEffect, useState } from 'react'
import { RouterProps } from './types'
import { decorateUrlWithLanguagePath } from "./utils";
import { useCookie } from "../utils/cookies";

const { REACT_APP_DASHBOARD_URL } = process.env

export const NoAuthRoute = ({ children }: RouterProps): JSX.Element | null => {
  const location = useLocation()
  const [token] = useCookie('token')
  const [user] = useLocalStorage<IUser>('user')
  const [redirect, setRedirect] = useState('')
  useEffect(() => {
    const routes = [
      'register',
      'login',
      'emailverify',
      'verification-code',
      'welcome'
    ]
    // dont navigate on these routes because they handle it internally
    if (
      token &&
      user &&
      !routes.some(route => location.pathname.includes(route))
    ) {
      setRedirect(decorateUrlWithLanguagePath(REACT_APP_DASHBOARD_URL || '/dashboard'))
    }
    // change in location will rerender this indefinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user])
  if (redirect) {
    window.location.href = redirect
    return null
  }
  return children
}
