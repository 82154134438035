import Cookies from 'js-cookie'

//determines if the user has a set theme
export const detectColorScheme = (theme?: 'light' | 'dark' | 'auto') => {
  const storedTheme = theme ?? (Cookies.get('color-scheme') || 'light')
  //local storage is used to override OS theme settings
  if (storedTheme === 'light' || storedTheme === 'dark') {
    return storedTheme
  } else if (!window.matchMedia) {
    //matchMedia method not supported
    return 'light'
  } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //OS theme setting detected as dark
    return 'dark'
  }
  return 'light'

  //dark theme preferred, set document with a `data-theme` attribute
}
