import Cookies from "js-cookie";
import { defaultCookieOptions } from "./cookies";

export const USER_KEY = 'user'
export const TOKEN = 'token'
export const FID_LOGGED = 'fid-is-loggedin'
export const SESSION_TOKEN_KEY = 'session_token';

class UserAuthClass {
  static setLoggedUserStatus (user: Record<string, string>, token: string) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    Cookies.set(TOKEN, token, defaultCookieOptions)
    Cookies.set(FID_LOGGED, 'true', defaultCookieOptions)
  }

  static setLogoutUserStatus () {
    Cookies.remove(FID_LOGGED, {
      domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
    })
    window.localStorage.removeItem(USER_KEY)
    window.localStorage.removeItem(TOKEN)
    Cookies.remove(TOKEN)
    Cookies.remove(SESSION_TOKEN_KEY)
  }
}

export default UserAuthClass;
