import React, { useEffect, useMemo, useState } from 'react'
import DateDropdown from './DateDropdown'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { useIntl } from 'react-intl'
import { Container } from './styles'
import { Props } from './types'
import { OPTIONS, PLACEHOLDERS } from './constants'
import { validateBirthDate } from "./utils";
import ErrorMessage from "../ErrorMsg";


const DateInput = ({ label, value, change, editable, name }: Props) => {
  const intl = useIntl()
  const [isValid, setValid] = useState<undefined | boolean>(undefined);
  const initialDate = value
    ? value
        .split('T')[0]
        .split('-')
        .map((part) => ({ value: part, label: part }))
    : [
        { value: '', label: '' },
        { value: '', label: '' },
        { value: '', label: '' },
      ]
  const [dateArr, setDateArr] = useState(initialDate)

  const handleChange = (index: number) => (value: {
    value: string
    label: string
  }) => {
    setDateArr((dateArr) => dateArr.map((_, i) => (i === index ? value : _)))
  }

  useEffect(() => {
    const isValid = validateBirthDate(dateArr, name);
    setValid(isValid);
    change(dateArr.map((part) => part.value).join('-'), isValid)
    // eslint-disable-next-line
  }, [dateArr])

  const showError = useMemo(
    () => {
      const value = dateArr.map((part) => part.value).join('');
      return isValid === null || isValid === undefined ? false : !!value && !isValid
    },
    [isValid, dateArr])

  const actualDate = new Date(value || '')

  return editable ? (
    <Container>
      {dateArr.map((val, index) => {
        const placeholder = intl.formatMessage({
          id: `components.date.${PLACEHOLDERS[index]}`,
        })
        return (
          <DateDropdown
            isYear={index === 0}
            key={index}
            placeholder={placeholder}
            change={handleChange(index)}
            name={`date-dropdown-${index}`}
            options={OPTIONS[index]}
            value={val}
            label={intl.formatMessage({
              id: `components.date.${PLACEHOLDERS[index]}Aria`,
            })}
            isValid={isValid}
          />
        )
      })}
      {showError && <ErrorMessage type={name || 'dob'}/>}
    </Container>
  ) : (
    <ReadOnly value={actualDate.toLocaleDateString()} fieldName={label} />
  )
}

export { DateInput }
