import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { IconLabelBtn } from '../../../components'
import { Redirect } from 'react-router-dom'
import FormWrapper from './FormWrapper'
import { IProfileObject } from '../../../models/IProfileField'
import useSWR from 'swr'
import { apiService } from '../../../service/api/apiService'
import profileData from '../../../assets/data/profileData.json'
import { Section, SectionItem } from '../Section'
import { useAuthorize } from '../../authorize/useAuthorize'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { TopLoadingBarContext } from '../../../utils/ContextWrapper'
import { decorateUrlWithLanguagePath } from "../../../routing/utils";

const Profile = () => {
  const intl = useIntl()
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  //Initialize with 2 to make "firstName" the first focussed field
  const [stepIndex, setStepIndex] = useState(2)
  const { isAuthorizeFlow } = useAuthorize()
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)
  const [user] = useLocalStorage<IUser>('user')
  const [redirect, setRedirect] = useState('')

  const { data, error } = useSWR(
    !!user ? ['user', user.id] : null,
    (key: string, userId: string) => apiService.user.get(userId),
  )

  useEffect(() => {
    if (isAuthorizeFlow) {
      setRedirect(decorateUrlWithLanguagePath('/mandatory-fields'))
    }
  }, [isAuthorizeFlow])

  useEffect(() => {
    if (!data) {
      setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
    } else if (data || error) {
      setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
    }
  }, [data, error, setTopLoadingBar])

  if (error) {
    console.error(error)
    return null
  }
  if (!data) {
    return null
  }

  const steps = profileData.map((field) => {
    const value = data[field.name]
    // handle linkedin seperately as its backend structure is an array which might contain the entry, but not the url itself
    const linkedinComplete =
      field.name === 'socialMedia' && value?.length ? !!value[0].url : false
    const completed =
      field.name === 'socialMedia'
        ? linkedinComplete
        : field.name === 'password' ||
          field.name === 'newsletters' ||
          (Array.isArray(value) ? value.length > 0 : !!value)
    return {
      ...field,
      value,
      completed,
    }
  })

  const currentStep = steps[stepIndex ?? 0]

  const makeCurrentField = (step: IProfileObject, index: number) => {
    if (step.field === 'redirect') {
      if (step.path) {
        setRedirect(step.path)
      }
    } else {
      setIsOverlayOpen(true)
      setStepIndex(index)
    }
  }

  const handleIconClick = (step: IProfileObject, index: number) => () => {
    makeCurrentField(step, index)
  }

  if (!currentStep) {
    return null
  }
  const allCompleted =
    steps?.filter((step: IProfileObject) => step.completed)?.length ===
    steps?.length

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Section>
      {steps?.map((step: IProfileObject, i: number) => {
        const { label, iconName, completed } = step
        return (
          <SectionItem aria-hidden={isOverlayOpen} key={label}>
            <IconLabelBtn
              hasCheckIcon={!allCompleted && completed}
              completed={completed}
              iconFileName={iconName}
              label={intl.formatMessage({ id: `${label}` })}
              onClick={handleIconClick(step, i)}
            />
          </SectionItem>
        )
      })}
      <FormWrapper
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
        steps={steps}
        currentIndex={stepIndex}
        setCurrentIndex={setStepIndex}
      />
    </Section>
  )
}

export default React.memo(Profile)
