import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorMessageWrapper } from "./styles";
import { ErrorMessageProps } from "./types";
import { errorMessageId } from "./utils";

const ErrorMessage = ({type}: ErrorMessageProps) => {
  const intl = useIntl()
  const isRtl = intl.locale === 'ar'
  const errorMessageString = errorMessageId[type] || 'errors.validation.general';

  return (
    <ErrorMessageWrapper className="error-validation-message" isRtl={isRtl}>
      <FormattedMessage id={errorMessageString}/>
    </ErrorMessageWrapper>
  );
}

export default ErrorMessage
