import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { AuthFormView } from '../../components'
import { isEmailValid } from '../../utils/validation'
import Content from './Content'
import { useAuthorize } from '../authorize/useAuthorize'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../../components/ToastMessage/ToastService'
import {
  AppContext,
  AuthData,
  AuthDataContext,
  TopLoadingBarContext,
  UrlContext,
} from '../../utils/ContextWrapper'
import queryString from 'query-string'
import useLocalStorage, { deleteFromStorage } from '@rehooks/local-storage'
import { useIntl } from 'react-intl'
import { IUser } from '../../models/IUser'
import { deleteTokenCookie, setLoggedInCookie, useCookie } from '../../utils/cookies'
import { stepNumbers, steps } from './constants'
import { decorateUrlWithLanguagePath } from "../../routing/utils";
import { getProgramsSessionToken } from "./utils";
import UserAuthClass from "../../utils/UserAuthClass";

const { REACT_APP_DASHBOARD_URL } = process.env

const LoginPage = () => {
  const intl = useIntl()
  const location = useLocation()
  const [redirect, setRedirect] = useState('')
  const { oauth2_authorize_url: oauth2Url } = queryString.parse(location.search)
  const [user] = useLocalStorage<IUser>('user')
  const [token, setToken] = useCookie<string>('token')
  const [stepIndex, setStepIndex] = useState(0)
  const [hasLoginStarted, setHasLoginStarted] = useState(false)
  const { isAuthorizeFlow, authorize } = useAuthorize()
  const [url] = useContext(UrlContext)
  const [{ email, password }, setAuthData] = useContext(AuthDataContext)
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)
  const params = queryString.parse(location.search)
  const [, setAppState] = useContext(AppContext)

  const redirectTo = (nextParam?: string, urlString?: string) => {
    if(nextParam) {
      return window.location.assign(nextParam)
    }

    setRedirect(decorateUrlWithLanguagePath(urlString || (REACT_APP_DASHBOARD_URL || '/dashboard')))
  };

  useEffect(() => {
    if (params && Object.keys(params).length) {
      const {
        app_id: appId = '',
        next = '',
        resetToken,
      }: { app_id?: string; next?: string; resetToken?: string } = params
      if (resetToken) {
        deleteTokenCookie()
        deleteFromStorage('user')
      }

      setAppState((state) => ({
        ...state,
        authorizeData: {
          appId,
          next,
          resetToken: resetToken === 'true',
        },
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, setAppState])

  useEffect(() => {
    const { next = '' }: {next?: string } = params;
    if (user && token && !oauth2Url) {
      // only redirect when user and token are set, but the login process has not started yet.
      if (!hasLoginStarted) {
        redirectTo(next)
      }
      // if we are in authorize flow, start it after user and token are set
      else if (isAuthorizeFlow) {
        setLoggedInCookie()
        authorize()
      } else {
        redirectTo(next, url)
      }
    }
  }, [user, token, hasLoginStarted, isAuthorizeFlow, authorize, url, oauth2Url, params])

  const stepsList = [
    steps.EMAILPASSWORD,
    steps.LOGIN,
    steps.TWOFACTORTOKEN,
    steps.AUTHENTICATE,
  ]

  const isValid = () => isEmailValid(email) && password !== ''

  const handleNext = async () => {
    const nextStep = stepIndex + 1
    setHasLoginStarted(true)
    if (email && password && nextStep === stepNumbers.LOGIN) {
      try {
        setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
        const { token, user } = await apiService.auth.login(email, password)
        UserAuthClass.setLoggedUserStatus(user, token)
        // TODO: Check if we need to keep token in a hook
        setToken(token)
        await getProgramsSessionToken()
        if (isAuthorizeFlow) {
          authorize()
        } else {
          if (oauth2Url) {
            const oauthRedirectUrl =
              (process.env.REACT_APP_BACKEND_AUTH_URL ?? '') + oauth2Url
            window.location.href = oauthRedirectUrl
          } else {
            const { next = '' }: {next?: string } = params;
            redirectTo(next, url);
            // TMP hack for WP side for pull cookies from the browser
            window.location.reload()
          }
        }
      } catch ({ response }) {
        if (response?.status === 403) {
          // validation flow
          setRedirect(`verification-code${location.search}`)
        } else if (response?.status === 499) {
          // tfa flow
          setStepIndex(stepNumbers.TWOFACTORTOKEN)
        } else if (response?.status === 401) {
          // unauthorized
          openToast({
            message: intl.formatMessage({ id: 'errors.invalidLogin' }),
            variant: 'failure',
          })
        } else {
          openToast({
            message: intl.formatMessage({ id: 'errors.loginFail' }),
            variant: 'failure',
          })
        }
      } finally {
        setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
      }
    }
  }

  const handleEmailChange = (val: string, status: boolean) => {
    setAuthData((authData) => ({ ...authData, email: val }))
  }

  const handlePasswordChange = (val: string, status: boolean) =>
    setAuthData((authData: AuthData) => ({ ...authData, password: val }))

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <AuthFormView>
        <Content
          step={stepsList[stepIndex]}
          onChangeEmail={handleEmailChange}
          onChangePassword={handlePasswordChange}
          stepLabels={stepsList}
          isValid={isValid()}
          onNextStep={handleNext}
          currentIndex={stepIndex}
        />
      </AuthFormView>
    </>
  )
}

export default LoginPage
