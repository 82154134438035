import { axiosIdentity } from './axiosInstances'
import { AxiosResponse } from 'axios'
import { INewsletter, INewsletterResponse } from '../../models/INewsletter'

export const getNewsletter = (id: string) => {
  return axiosIdentity
    .get(`/newsletters/${id}`)
    .then((response: AxiosResponse<INewsletter>) => response.data)
}
export const getNewsletters = () => {
  return axiosIdentity
    .get(`/newsletters`)
    .then((response: AxiosResponse<INewsletterResponse>) => response.data)
}

export const subscribeNewsletter = (mailchimpId: string) => {
  return axiosIdentity
    .put(`/newsletters/${mailchimpId}/subscribe`)
    .then((response: AxiosResponse<INewsletter>) => response.data)
}

export const unsubscribeNewsletter = (mailchimpId: string) => {
  return axiosIdentity
    .put(`/newsletters/${mailchimpId}/unsubscribe`)
    .then((response: AxiosResponse<INewsletter>) => response.data)
}
