import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// @ts-ignore
import Helmet from 'react-helmet'
import { animated, useTransition } from 'react-spring'

import ContextWrapper from '../utils/ContextWrapper'
import { StyleSheetManager } from 'styled-components'
import rtlPlugin from 'stylis-plugin-rtl'
import { StickyContainer } from 'react-sticky'
import { writeStorage } from '@rehooks/local-storage'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { apiService } from '../service/api/apiService'
import CustomRouting from './CustomRouting'
import UserAuthClass from "../utils/UserAuthClass";

const Routing = () => {
  const intl = useIntl()
  const location = useLocation()

  const dir = intl.locale === 'ar' ? 'rtl' : 'ltr'
  const { data, error } = useSWR('validate', () => apiService.user.validate())
  useEffect(() => {
    if (data) {
      writeStorage('user', data)
    } else if (error) {
      UserAuthClass.setLogoutUserStatus();
      // TODO: Check if we need the whole method or just commented part
      // deleteFromStorage('user')
      // deleteTokenCookie()
    }
  }, [data, error])

  const transitions = useTransition(location, location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    document.body.style.direction = dir
  }, [dir])

  if (!data && !error) {
    return null
  }

  return (
    <StickyContainer>
      <StyleSheetManager stylisPlugins={dir === 'rtl' ? [rtlPlugin] : []}>
        <ContextWrapper>
          {transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={props}>
              <>
                <Helmet>
                  <title>{intl.formatMessage({ id: 'pageInfo.title' })}</title>
                  <meta
                    property='og:title'
                    content={intl.formatMessage({ id: 'pageInfo.title' })}
                  />
                  <meta
                    property='og:description'
                    content={intl.formatMessage({ id: 'pageInfo.description' })}
                  />
                </Helmet>
                <main>
                  <CustomRouting />
                </main>
              </>
            </animated.div>
          ))}
        </ContextWrapper>
      </StyleSheetManager>
    </StickyContainer>
  )
}

export default Routing
