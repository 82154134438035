import React, { useState } from 'react'
import { FormContainer } from '../../../components'
import OverlayField from '../OverlayField'
import { IProfileObject } from '../../../models/IProfileField'
import { apiService } from '../../../service/api/apiService'
import useSWR from 'swr'
import { openToast } from '../../../components/ToastMessage/ToastService'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { useIntl } from 'react-intl'
import { excludedOrganisationFieldsForUpdate, TRAVERSE } from './constants'
import { IFormWrapperProps } from './types'

const FormWrapper = ({
  id,
  isOverlayOpen,
  setIsOverlayOpen,
  organisationSteps,
  currentIndex,
  setCurrentIndex,
  isUserAdmin,
}: IFormWrapperProps) => {
  const intl = useIntl()
  const [user] = useLocalStorage<IUser>('user')
  const { data, mutate } = useSWR(
    !!user ? ['organisations', user.id] : null,
    (key: string, userId: string) => apiService.user.organisations(userId),
  )

  const [enableSubmit, setEnableSubmit] = useState(false)
  const currentStep = organisationSteps[currentIndex]
  const saveField = async (step: IProfileObject) => {
    const { field, value, name } = step
    // Ignore members, pending requests and pending invitations
    if (field && excludedOrganisationFieldsForUpdate.includes(field)) {
      return
    }

    if (
      name === 'logo' &&
      // eslint-disable-next-line
      value instanceof File
    ) {
      try {
        const organisation = await apiService.organisation.addLogo(id, value)
        if (data) {
          mutate(
            data.map((org) =>
              org.id === id && name ? { ...organisation } : org,
            ),
            false,
          )
        }
      } catch (e) {
        openToast({
          variant: 'failure',
          message: intl.formatMessage({
            id: 'errors.uploadPhotoFail',
          }),
        })
      }
    } else {
      if (data) {
        mutate(
          data.map((organisation) =>
            organisation.id === id && name
              ? { ...organisation, [name]: value }
              : organisation,
          ),
          false,
        )
      }
    }
  }

  const onUpdateField = async () => {
    const { name, value, field } = currentStep
    if (field && [...excludedOrganisationFieldsForUpdate, 'logo'].includes(field)) {
      return
    }

    if (name && value) {
      try {
        await apiService.organisation.update(id, name, value)
      } catch (e) {
        openToast({
          variant: 'failure',
          message: intl.formatMessage({
            id: 'errors.updateFieldFail',
          }),
        })
      }
    }
  }

  const isValid = () => {
    if (!isUserAdmin) {
      return true
    }
    if (currentStep) {
      return !!currentStep.skipValidity || !!(currentStep.value && enableSubmit)
    }

    return false
  }

  const stepsTraversing = async (direction: TRAVERSE, close?: boolean) => {
    if (direction === TRAVERSE.FORWARD) {
      if (enableSubmit && isUserAdmin) {
        saveField(currentStep)
      }
      if (!close) {
        setCurrentIndex((currentIndex) => currentIndex + 1)
      } else {
        setIsOverlayOpen(false)
      }
    } else if (direction === TRAVERSE.REVERSE) {
      isUserAdmin && saveField(currentStep)
      setCurrentIndex((currentIndex) => currentIndex - 1)
    }
  }

  const handleClose = () => {
    isUserAdmin && isValid() && saveField(currentStep)
    setIsOverlayOpen(false)
  }

  const updateFieldsData = (
    value: string | File | string[],
    status: boolean,
  ) => {
    setEnableSubmit(status)

    if (status && isUserAdmin) {
      saveField({
        ...currentStep,
        value,
      })
    }
  }

  return (
    <FormContainer
      enableProgressBar
      progressType='organisation'
      isOpen={isOverlayOpen}
      title={
        currentStep
          ? `${intl.formatMessage({ id: `${currentStep?.label}` })} ${
              currentStep?.name === 'members' ||
              currentStep?.name === 'pendingRequests' ||
              currentStep?.name === 'pendingInvitations'
                ? `(${currentStep.value?.length ?? 0})`
                : ''
            }`
          : ''
      }
      setCurrentIndex={setCurrentIndex}
      onNextStep={(step, close) => stepsTraversing(TRAVERSE.FORWARD, close)}
      onPrevStep={() => stepsTraversing(TRAVERSE.REVERSE)}
      isValid
      steps={organisationSteps}
      stepLabels={organisationSteps.map((step) => step.label || '')}
      onClose={handleClose}
      currentIndex={currentIndex}
      onStepSubmit={onUpdateField}
    >
      <OverlayField
        organisationId={id}
        editable={isUserAdmin}
        step={currentStep}
        updateFieldsData={updateFieldsData}
      />
    </FormContainer>
  )
}

export default FormWrapper
