import { useContext, useEffect, useState } from 'react'
import { UrlContext } from '../utils/ContextWrapper'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../models/IUser'
import { useLocation } from 'react-router-dom'
import { RouterProps } from './types'
import { decorateUrlWithLanguagePath } from "./utils";
import { useCookie } from "../utils/cookies";

const { REACT_APP_LOGIN_URL } = process.env

export const AuthRoute = ({ children }: RouterProps): JSX.Element | null => {
  const location = useLocation()
  const [, setUrl] = useContext(UrlContext)
  const [user] = useLocalStorage<IUser>('user')
  const [redirect, setRedirect] = useState('')
  const [token] = useCookie('token')
  useEffect(() => {
    if (!user || !token) {
      setUrl(`${location?.pathname}${location?.search}`)
      setRedirect(decorateUrlWithLanguagePath(REACT_APP_LOGIN_URL || '/login'))
    }
    // change in location will rerender this indefinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token])
  if (redirect) {
    window.location.href = redirect
    return null
  }
  return user && token ? children : null
}
