import styled from 'styled-components'
import { getBorderColor, getColor } from './utils'
import {
  AdornmentContainerType,
  EmailContainerType,
  InputContainerType,
  StyledInputType,
} from './types'
import TextareaAutosize from 'react-autosize-textarea'

export const EmailContainer = styled.div<EmailContainerType>`
  display: flex;
  flex-direction: column;
  width: ${({ fluid }) => fluid && '100%'};
  margin: ${({ theme }) => theme.spacing.sm} 0;
`
export const ErrorText = styled.span`
  color: var(--red);
  margin-top: 5px;
  font-size: ${({ theme }) => theme.fontSize.sm};
`

export const ErrorTextPassword = styled(ErrorText)`
  margin-bottom: 7px;
`

export const StyledInput = styled.input<StyledInputType>`
  height: 50px;
  background-color: var(--background);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: ${({ startAdornment, rtl, endAdornment }) =>
    endAdornment && rtl ? 'none' : startAdornment ?  !rtl ? '1px solid' : 'none' : '1px solid' };
  border-right: ${({ endAdornment, rtl }) => (endAdornment ? !rtl ? 'none' : '1px solid' : '1px solid')};
  border-color: ${({ status }) => getBorderColor(status)};
  margin: 0;
  padding: ${({theme, rtl }) => rtl ? `0 ${theme.spacing.md} 0 0` : `0 0 0 ${theme.spacing.md}`};
  color: var(--primary);
  flex: 1;
  &:focus {
    outline: auto 1px ${({ status }) => getBorderColor(status)};
  }
`
export const InputContainer = styled.div<InputContainerType>`
  display: flex;
  width: calc(100% - 4px);
  padding: 2px;
  max-width: ${({ fluid }) => (fluid ? '' : '25rem')};
  min-width: ${({ fluid }) => (fluid ? '' : '10rem')};
`
export const AdornmentContainer = styled.div<AdornmentContainerType>`
  width: 4rem;
  min-width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: ${({ status }) => getBorderColor(status)};
  &:first-child {
    border: 1px solid;
    border-left: ${({rtl}) => rtl ? 'none' : '1px solid'};
    border-right: ${({rtl}) => rtl ? '1px solid' : 'none'};
  }
  &:last-child {
    border: 1px solid;
    border-left: ${({rtl}) => rtl ? '1px solid' : 'none' };
    border-right: ${({rtl}) => rtl ? 'none' : '1px solid' };
  }
`
export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.sm} 0;
`
export const VisibilityButton = styled.button`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
`
export const PasswordScaleContainer = styled.div`
  display: flex;
  margin: 0;
`
export const ScaleItem = styled.div<{ hasColor: boolean; score: number }>`
  height: 2px;
  background-color: ${({ hasColor, score }) => getColor(hasColor, score)};
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacing.sm};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`
export const TextAreaContainer = styled.div`
  width: calc(100% - 2px);
  margin-top: ${({ theme }) => theme.spacing.md};
`
export const Words = styled.span`
  margin: 5px 10px;
  position: relative;
  float: right;
  color: var(--primary);
  font-size: $font-mini;
  text-transform: uppercase;
`
export const StyledTextArea = styled(TextareaAutosize)`
  color: var(--primary);
  background: var(--background);
  width: calc(100% - 40px);
  border: 1px solid var(--grey);
  cursor: text;
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 24px;
  height: 200px;
  padding: ${({ theme }) => theme.spacing.md};
  @media (max-width: 480px) {
    height: 100px;
  }
  @media (max-height: 520px) {
    height: 50px;
  }
`
export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const StyledLink = styled.a`
  color: var(--primary);
  text-shadow: 0 0 0 var(--primary);
  text-align: center;
`
