import React, { useEffect, useState } from 'react'
import { apiService } from '../../service/api/apiService'
import { FormContainer } from '../../components'
import Content from './Content'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'
import { openToast } from '../../components/ToastMessage/ToastService'
import queryString from 'query-string'
import { getSessions, removeSession } from '../../service/connections'
import { renderErrorToast } from '../../utils/errorToast'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { steps } from './constants'
import { buf2hex } from './utils'
import { UpdatePasswordPageContainer } from './styles'
import { decorateUrlWithLanguagePath } from "../../routing/utils";

const { REACT_APP_DASHBOARD_URL } = process.env

const UpdatePasswordPage = () => {
  const [newPassword, setNewPassword] = useState('')
  const [loading, setLoading] = useState(true)
  const [retypedPassword, setRetypedPassword] = useState('')
  const [redirect, setRedirect] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPasswordStatus, setNewPasswordStatus] = useState(false)
  const [step, setStep] = useState<steps>(steps.CURRENT)
  const stepsList = [steps.CURRENT, steps.NEW, steps.COMPLETE]
  const [user] = useLocalStorage<IUser>('user')
  const intl = useIntl()

  useEffect(() => {
    const { device } = queryString.parse(window.location.search)
    if (device && intl) {
      getSessions()
        .then((devices) => {
          const deviceToDelete = devices.find(async (d) => {
            const msgUint8 = new TextEncoder().encode(d.id)
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
            return buf2hex(hashBuffer) === device
          })
          if (deviceToDelete) {
            removeSession(deviceToDelete.id)
              .then((res) => {
                // ok
                setLoading(false)
              })
              .catch((err) => {
                console.error(err)
                setLoading(false)
              })
          } else {
            setLoading(false)
            const message = intl.formatMessage({
              id: 'errors.sessionInvalidateFail',
            })
            openToast({
              variant: 'info',
              message,
            })
          }
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [intl])

  const handleNewPasswordChange = (val: string, status: boolean) => {
    setNewPassword(val)
    setNewPasswordStatus(status)
  }

  const handleUpdatePassword = () => {
    apiService.auth
      .updatePassword(currentPassword, newPassword)
      .then(() => {
        openToast({
          message: intl
            .formatMessage({ id: 'auth.updatePassword.success' })
            .toString(),
          variant: 'success',
        })
        setRedirect('/')
      })
      .catch(({ response }) => {
        renderErrorToast(response, 'errors.updatePasswordFail', intl)
        setRedirect('/')
      })
  }

  const getCurrentStepIndex = () => {
    switch (step) {
      case steps.CURRENT:
        return 0
      case steps.NEW:
        return 1
      case steps.COMPLETE:
        return 2
      default:
        throw new Error('invalid step reached')
    }
  }

  const handleNextStep = () => {
    switch (getCurrentStepIndex()) {
      case 0: {
        if (!user) break
        apiService.auth
          .checkPassword(user.id, currentPassword)
          .then(() => {
            setStep(stepsList[getCurrentStepIndex() + 1])
          })
          .catch(({ response }) => {
            renderErrorToast(
              response,
              response.status === 403
                ? 'errors.wrongPassword'
                : 'errors.genericFail',
              intl,
            )
          })
        break
      }

      case 1: {
        if (retypedPassword !== newPassword) {
          openToast({
            message: intl.formatMessage({
              id: 'errors.differentPasswords',
            }),
            variant: 'failure',
          })
        } else if (!newPasswordStatus) {
          openToast({
            message: intl.formatMessage({
              id: 'errors.insecurePassword',
            }),
            variant: 'failure',
          })
        } else {
          handleUpdatePassword()
        }
        break
      }
      default:
        break
    }
  }

  const handlePrevStep = () => {
    const prev = stepsList[getCurrentStepIndex() + -1]
    setStep(prev)
  }
  const handleChangeCurrent = (val: string, status: boolean) =>
    setCurrentPassword(val)
  const handleChangeRetyped = (val: string, status: boolean) =>
    setRetypedPassword(val)
  if (loading) {
    return <div style={{ height: 'calc(100vh - 70px)' }} />
  }

  const handleClose = () => setRedirect(decorateUrlWithLanguagePath(REACT_APP_DASHBOARD_URL || '/dashboard'))

  if (redirect) return <Redirect to={redirect} />

  return (
    <UpdatePasswordPageContainer>
      <FormContainer
        isOpen
        title={intl.formatMessage({
          id: step,
        })}
        isValid={
          step === steps.CURRENT || (step === steps.NEW && newPasswordStatus)
        }
        onPrevStep={(step: any) => handlePrevStep()}
        onNextStep={(step: any) => handleNextStep()}
        stepLabels={stepsList}
        onClose={handleClose}
        currentIndex={getCurrentStepIndex()}
      >
        <Content
          step={step}
          currentPassword={currentPassword}
          newPassword={newPassword}
          retypedPassword={retypedPassword}
          onChangeCurrent={handleChangeCurrent}
          onChangeNewPassword={handleNewPasswordChange}
          onChangeRetypedPassword={handleChangeRetyped}
          onEnter={handleNextStep}
        />
      </FormContainer>
    </UpdatePasswordPageContainer>
  )
}

export default UpdatePasswordPage
