import styled from 'styled-components'
import { customStyles } from '../Dropdown/styles'
import React from 'react'

export const MenuAction = styled.div<{ rtl: boolean }>`
  svg {
    fill: var(--primary) !important;
    transform: rotateZ(90deg);
  }
`
export const ActionBtn = styled.button`
  border: none;
  background: transparent;
  outline: unset;
  cursor: pointer;
  margin: 0 3rem;
  svg {
    width: 3rem;
    @media (max-width: 480px) {
      width: 2rem;
    }
  }
`
export const AddHeader = styled.button`
  background: none;
  border: none;
  font-family: 'NeoSans', sans-serif;
  margin: 4rem 0;
  @media only screen and (max-width: 960px) {
    margin: 3rem 0;
  }
  @media only screen and (max-width: 700px) {
    margin: 2rem 0;
  }
`
export const locationStyles = {
  ...customStyles,
  menuPortal: (base: React.CSSProperties) => ({ ...base, zIndex: 1500 }),
  clearIndicator: (provided: React.CSSProperties) => ({
    ...provided,
    color: 'var(--primary)',
  }),

  indicatorSeparator: (provided: React.CSSProperties) => ({
    ...provided,
  }),

  dropdownIndicator: (provided: React.CSSProperties, { isRtl }: any): any => ({
    ...provided,
  }),
}
export const Container = styled.div`
  max-width: 50rem;
  width: 100%;
`
export const Header = styled.div<{ hideMobile?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 4rem 0rem;
  @media only screen and (max-width: 960px) {
    margin: 3rem 0rem;
  }
  @media only screen and (max-width: 700px) {
    margin: 2rem 0rem;
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
  }
`
export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: bold;
  color: var(--primary);
  @media only screen and (max-width: 700px) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
  margin: 0.8em auto;
`
