import styled from 'styled-components'
import { TextWrapperProps } from "./types";

export const Wrapper = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 4rem);
  padding: 0;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`
export const Header = styled.h4`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: ${({ theme }) => theme.fontSize.lg};
  color: var(--primary);
`
export const Description = styled.span`
  word-wrap: break-word;
  display: flex;
  color: var(--primary);
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.fontSize.md};
  text-align: justify;
  line-height: 2.5rem;
`
export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({rtl}) => rtl ? 'margin-left: 6rem' : 'margin-right: 6rem'}};
  @media (max-width: 900px) {
    margin-bottom: 1rem;
    ${({rtl}) => rtl ? 'margin-left: 0' : 'margin-right: 0'}};
  }
`
export const Separator = styled.div`
  border-top: 1px solid var(--darkgrey);
  margin: 2rem 0rem;
  width: 100%;
`
export const NewslettersWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: calc(100vh - 480px);
  @media (max-width: 480px) {
    width: 100%;
    max-height: calc(100vh - 300px);
  }
  @media (max-height: 520px) {
    max-height: 100%;
  }
`
