/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react'
import validate from '../../utils/fieldsValidator'
import { TextArea } from './TextArea'
import { useIntl } from 'react-intl'
import { CustomInput as Input } from './Input'
import debounce from 'lodash.debounce'
import { TextInputProps } from './types'
import ErrorMessage from "../ErrorMsg";

const TextInput = ({
  label,
  value,
  disableDebounce,
  change,
  type = 'text',
  placeholder,
  maxLength = 200,
  editable = true,
  disableRtl = false,
  showStatusHints = false,
  valid,
  disabled,
  status,
  ...props
}: TextInputProps) => {
  const intl = useIntl()
  const [inputValue, setInputValue] = useState(value)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(debounce(change, 400), [])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target
    setInputValue(value)
    const valid = validate({ value, type }) === 'success'
    disableDebounce ? change(value, valid) : handler(value, valid)
  }

  const validStatus = useMemo(() => {
    return status || showStatusHints
      ? validate({ type, value: inputValue?.toString() ?? '' })
      : undefined
  }, [inputValue, status, showStatusHints, type])

  const showError = useMemo(
    () => !inputValue && !(validStatus === 'success'),
    [validStatus, inputValue])

  const rtl = !disableRtl && intl.locale === 'ar'
  return type === 'textarea' ? (
    <TextArea
      autoFocus
      value={inputValue}
      aria-label={label}
      placeholder={placeholder}
      readonly={!editable}
      maxLength={maxLength}
      onChange={
        handleChange as (e: React.ChangeEvent<HTMLTextAreaElement>) => void
      }
    />
  ) : (
    <>
      <Input
        {...props}
        readonly={!editable}
        rtl={rtl}
        status={validStatus}
        aria-label={label}
        value={inputValue}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={
          handleChange as (e: React.ChangeEvent<HTMLInputElement>) => void
        }
      />
      {showError && <ErrorMessage type={type} />}
    </>
  )
}

export { TextInput }
export default TextInput
