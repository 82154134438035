import React, { useState, useEffect, useContext } from 'react'
import FormWrapper from '../dashboard/Profile/FormWrapper'
import useLocalStorage from '@rehooks/local-storage'
import { useAuthorize } from '../authorize/useAuthorize'
import { FormattedMessage } from 'react-intl'
import { Redirect } from 'react-router'
import useSWR from 'swr'
import { IUser } from '../../models/IUser'
import { usePrevious } from '../../utils/hooks/usePrev'
import profileData from '../../assets/data/profileData.json'
import { apiService } from '../../service/api/apiService'
import { ConfirmOverlay } from '../../components'
import { AppContext } from '../../utils/ContextWrapper'

const MandatoryFiedls = () => {
  const [isConfirmOverlayOpen, setIsConfirmOverlayOpen] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const { isAuthorizeFlow, authorize } = useAuthorize()
  const [{ authorizeData, mandatoryFields }, setAppState] = useContext(
    AppContext,
  )
  const { appId, isRegister } = authorizeData
  const [user] = useLocalStorage<IUser>('user')
  const { data, error } = useSWR(
    !!user ? ['user', user.id] : null,
    (key: string, userId: string) => apiService.user.get(userId),
  )

  const prevData = usePrevious(data)

  useEffect(() => {
    if (data) {
      const missingFieldIndex = profileData
        .filter(
          (field) =>
            mandatoryFields.includes(field.name) &&
            field.name !== 'email' &&
            field.name !== 'password',
        )
        .findIndex((field) => {
          if (field.name === 'newsletters') {
            return false
          }
          return data[field.name] && Array.isArray(data[field.name])
            ? !data[field.name].length
            : !data[field.name]
        })

      // only run this the first time data is fetched to prevent setting the step index on every change, causing auto submit
      if (prevData === undefined && missingFieldIndex > 0) {
        setStepIndex(missingFieldIndex)
      } else if (missingFieldIndex === -1 && mandatoryFields.length) {
        const submitBtn = document.querySelectorAll('button[type="submit"]')[0] as HTMLElement;
        submitBtn?.click()
        setIsConfirmOverlayOpen(true)
      }
    }
  }, [data, prevData, mandatoryFields])

  if (!isAuthorizeFlow) {
    return <Redirect to={isRegister ? '/welcome' : '/dashboard'} />
  }

  if (!data || error) {
    return null
  }

  const steps = profileData
    .map((field) => {
      const value = data[field.name]
      // handle linkedin seperately as its backend structure is an array which might contain the entry, but not the url itself
      const linkedinComplete =
        field.name === 'socialMedia' && value?.length ? !!value[0].url : false
      const completed =
        field.name === 'socialMedia'
          ? linkedinComplete
          : field.name === 'password' ||
            field.name === 'newsletters' ||
            (Array.isArray(value) ? value.length > 0 : !!value)
      return {
        ...field,
        value,
        completed,
      }
    })
    .filter(
      (step) => mandatoryFields.includes(step.name) && step.name !== 'email',
    )

  const handleMandatoryConfirmOverlayClose = () => {
    setAppState((state) => ({
      ...state,
      authorizeData: {
        appId: '',
        next: '',
        resetToken: false,
        isRegister: state.authorizeData.isRegister,
      },
    }))
  }

  return (
    <>
      <FormWrapper
        isOverlayOpen
        setIsOverlayOpen={() => {
          // eslint-disable-next-line no-console
          console.info('')
        }}
        steps={steps}
        currentIndex={stepIndex}
        setCurrentIndex={setStepIndex}
      />
      <ConfirmOverlay
        open={!!isConfirmOverlayOpen}
        onConfirm={authorize}
        title={<FormattedMessage id='mandatoryFields.overlayTitle' />}
        question={
          <FormattedMessage
            id='mandatoryFields.overlayDescription'
            values={{
              platform: appId ? (
                <FormattedMessage id={`settings.platforms.${appId}`} />
              ) : (
                ''
              ),
            }}
          />
        }
        confirmTitle={
          <FormattedMessage
            id='mandatoryFields.backTitle'
            values={{
              platform: appId ? (
                <FormattedMessage id={`settings.platforms.${appId}`} />
              ) : (
                ''
              ),
            }}
          />
        }
        denyTitle={<FormattedMessage id='mandatoryFields.stayTitle' />}
        onClose={handleMandatoryConfirmOverlayClose}
      />
    </>
  )
}

export default MandatoryFiedls
