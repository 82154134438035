import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CodeInput } from '..'
import { Redirect, useLocation } from 'react-router-dom'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../ToastMessage/ToastService'
import { AppContext, AuthDataContext } from '../../utils/ContextWrapper'
import queryString from 'query-string'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { useAuthorize } from '../../pages/authorize/useAuthorize'
import { IUser } from '../../models/IUser'
import { setLoggedInCookie, useCookie } from '../../utils/cookies'
import { CancelButton, CodeInputContainer, Container, Title } from './styles'
import { IProps } from './types'
import { decorateUrlWithLanguagePath } from "../../routing/utils";

const { REACT_APP_LOGIN_URL, REACT_APP_WELCOME_URL } = process.env

const VerificationCode = ({ redirect, onSubmit }: IProps) => {
  const intl = useIntl()
  const location = useLocation()
  const { oauth2_authorize_url: oauth2Url } = queryString.parse(location.search)
  const [{ email }] = useContext(AuthDataContext)
  const [redirectUrl, setRedirectUrl] = useState('')
  const { isAuthorizeFlow, authorize } = useAuthorize()
  const [, setAppState] = useContext(AppContext)
  const [user] = useLocalStorage<IUser>('user')
  const [token, setToken] = useCookie('token')

  useEffect(() => {
    if (user && token) {
      if (oauth2Url && redirect) {
        window.location.href = oauth2Url as string
      } else {
        setLoggedInCookie()
        if (isAuthorizeFlow && !onSubmit) {
          setAppState((state) => ({
            ...state,
            authorizeData: { ...state.authorizeData, isRegister: true },
          }))
          authorize()
        } else {
          onSubmit && onSubmit(true)
          redirect && setRedirectUrl(decorateUrlWithLanguagePath(REACT_APP_WELCOME_URL || '/welcome'))
        }
      }
    }
  }, [
    user,
    token,
    redirect,
    oauth2Url,
    isAuthorizeFlow,
    authorize,
    onSubmit,
    setAppState,
  ])

  const verify = async (code: string) => {
    try {
      const { token, user } = await apiService.auth.verifyCode(email, code)

      setToken(token)
      writeStorage('user', user)
    } catch (e) {
      openToast({
        message: intl.formatMessage({ id: 'errors.genericFail' }),
        variant: 'failure',
      })
      onSubmit && onSubmit(false)
    } finally {
    }
  }

  const handleChange = (newCode: string) => {
    const strCode = newCode.toString()
    if (strCode.length === 4) {
      verify(strCode)
    }
  }

  const onCancel = () => {
    setRedirectUrl(decorateUrlWithLanguagePath(`${REACT_APP_LOGIN_URL || '/login'}${location.search}`))
  }

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />
  }

  const resendVerifyEmail = async () => {
    try {
      await apiService.auth.resendVerifyEmail(email)
      openToast({
        message: intl.formatMessage({ id: 'auth.updateEmail.resendSuccess' }),
        variant: 'success',
      })
    } catch (e) {
      openToast({
        message: intl.formatMessage({ id: 'errors.genericFail' }),
        variant: 'failure',
      })
    }
  }

  return (
    <Container>
      <Title>
        <FormattedMessage id='auth.verificationCode.title' />
      </Title>
      <CodeInputContainer>
        <CodeInput
          length={4}
          name='verify'
          change={handleChange}
          secondaryAction={resendVerifyEmail}
          email={email}
        />
      </CodeInputContainer>
      <div>
        <CancelButton onClick={onCancel}>
          <FormattedMessage id='common.cancel' />
        </CancelButton>
      </div>
    </Container>
  )
}

export default VerificationCode
