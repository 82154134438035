import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FilledButton, OverlayScreen } from '../../components'
import { ReactComponent as FillDataIcon } from '../../assets/icons/other/intro_fill.svg'
import { ReactComponent as ServicesIcon } from '../../assets/icons/other/intro_services.svg'
import { ReactComponent as ExploreIcon } from '../../assets/icons/other/intro_explore.svg'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  Circle,
  Circles,
  Container,
  Label,
  Step,
  Steps,
  StyledCarousel,
} from './styles'

const slides = [
  { icon: <FillDataIcon />, label: 'auth.intro.step1', index: 1 },
  { icon: <ServicesIcon />, label: 'auth.intro.step2', index: 2 },
  { icon: <ExploreIcon />, label: 'auth.intro.step3', index: 3 },
]

interface Props {
  onClose?: any
}

const Intro = ({ onClose }: Props) => {
  const intl = useIntl()
  const isRtl = intl.locale === 'ar'
  const [index, setIndex] = useState(() => (isRtl ? 2 : 0))
  const steps = useMemo(() => (isRtl ? slides.reverse() : slides), [isRtl])

  const onSlideChange = (i: number) => setIndex(i)

  const renderSlides = (animated?: boolean) =>
    steps.map(({ icon, label, index }) => (
      <Step key={index} index={index} animated={animated}>
        {icon}
        <Label>
          <FormattedMessage id={label} />
        </Label>
      </Step>
    ))

  return (
    <OverlayScreen isOpen>
      <Container>
        <StyledCarousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={index}
          onChange={onSlideChange}
        >
          {renderSlides()}
        </StyledCarousel>
        <Steps rtl={isRtl}>{renderSlides(true)}</Steps>
        <Circles rtl={isRtl}>
          {steps.map((step, i) => (
            <Circle
              key={i}
              tabIndex={0}
              role='button'
              onClick={() => onSlideChange(i)}
              active={i === index}
            />
          ))}
        </Circles>
        <FilledButton onClick={onClose}>
          <FormattedMessage id='auth.intro.goProfile' />
        </FilledButton>
      </Container>
    </OverlayScreen>
  )
}

export default Intro
