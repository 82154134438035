import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { useIntl } from 'react-intl'
import { usePrevious } from '../../utils/hooks/usePrev'
import { apiService } from '../../service/api/apiService'
import { phoneSelectStyles } from './selectStyles'
import { Input } from '..'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { Container, SelectContainer } from './styles'
import { IValue } from './types'
import { TextInputProps } from '../TextInput/types'
import ErrorMessage from "../ErrorMsg";

const MobileNumber = ({
  editable,
  change,
  value = '',
  name,
  ...props
}: TextInputProps) => {
  const intl = useIntl()
  //only display the input value if the value from backend actually contains a dash. If not, it is the old input format, and it will not be displayed to avoid confusion.
  const inputValue = useMemo(
    () =>
      (value as string)?.includes('-')
        ? (value as string).substring((value as string).indexOf('-') + 1)
        : '',
    [value],
  )

  const [valid, setValid] = useState<null | boolean>(null)
  const [options, setOptions] = useState<IValue[]>()
  const [number, setNumber] = useState(inputValue)
  const [selectValue, setSelectValue] = useState<IValue | null>(null)
  const prevValue = usePrevious(value)

  const showError = useMemo(
    () => {
      return valid === null || valid === undefined ? false : !!value && !valid
    },
    [valid, value])

  const getOptions = async () => {
    const data = await apiService.countries.get()
    const remoteOptions = data.flatMap((country) => {
      if (country.dialCodes.length > 1) {
        return country.dialCodes.map((dialCode) => ({
          code: country.code,
          ar: country.ar,
          en: country.en,
          label: `${country.code} (+${dialCode})`,
          value: `+${dialCode}`,
        }))
      } else if (country.dialCodes.length === 1) {
        return {
          code: country.code,
          ar: country.ar,
          en: country.en,
          label: `${country.code} (+${country.dialCodes[0]})`,
          value: `+${country.dialCodes[0]}`,
        }
      } else {
        return {
          label: '',
          value: '',
        }
      }
    })
    return remoteOptions
  }
  const validate = async (value: IValue | null, inputValue: string) => {
    try {
      return await import('google-libphonenumber').then((instance) => {
        const number = instance.PhoneNumberUtil.getInstance().parseAndKeepRawInput(
          value?.value + inputValue,
          value?.code,
        )

        const regionCode = instance.PhoneNumberUtil.getInstance().getRegionCodeForNumber(
          number,
        )
        if (
          instance.PhoneNumberUtil.getInstance().isValidNumber(number) &&
          regionCode
        ) {
          setValid(true)
          return true
        } else {
          setValid(false)
          return false
        }
      })
    } catch (e) {
      setValid(false)
      console.error(e)
      return false
    }
  }

  useEffect(() => {
    getOptions().then((res) => setOptions(res))
  }, [])

  useEffect(() => {
    if (!prevValue && (value as string)?.includes('-')) {
      getOptions().then((res) => {
        const areaCode = (value as string | undefined)?.split('-')[0]
        const currentOption = areaCode
          ? res.find((option) => option.value === areaCode) ?? null
          : null
        setSelectValue(currentOption)
        if (value) {
          const inputVal = (value as string).includes('-')
            ? (value as string).substring((value as string).indexOf('-') + 1)
            : ''
          validate(currentOption, inputVal)
        }
      })
    }
  }, [value, prevValue])

  const handleChange = async (newValue: IValue | null) => {
    setSelectValue(newValue)
    if (newValue?.value) {
      const isValid = await validate(newValue, inputValue)
      change(newValue.value + '-' + inputValue, isValid)
    }
  }

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setNumber(e.target.value)
    if (selectValue?.value) {
      const isValid = await validate(selectValue, e.target.value)

      change(selectValue.value + '-' + e.target.value, isValid)
    }
  }

  const filterOptions = (
    candidate: {
      data: IValue
      value: string
      label: string
    },
    input: string | undefined,
  ) => {
    if (input) {
      if (input.startsWith('00')) {
        const searchValue = input.substr(2)
        return candidate.value?.includes(searchValue)
      }
      return (
        candidate.data.code?.includes(input.toLowerCase()) ||
        candidate.data.ar?.toLowerCase().includes(input.toLowerCase()) ||
        candidate.data.en?.toLowerCase().includes(input.toLowerCase()) ||
        candidate.value.includes(input.toLowerCase()) ||
        candidate.label.includes(input.toLowerCase())
      )
    }
    return true
  }

  const handleFormat = (option: IValue, second: any) => {
    //only render the area code for the currently selected value
    return second.context === 'value'
      ? option.value
      : `${intl.locale === 'ar' ? option.ar : option.en} (${option.value})`
  }

  return editable ? (
    <Container dir='ltr' style={{ direction: 'ltr !important' as any }}>
      <SelectContainer>
        <Select
          autoFocus
          aria-label={`Select Area Code`}
          data-cy='select'
          id='mobile-select'
          filterOption={filterOptions as any}
          value={selectValue as any}
          menuPortalTarget={document.body}
          options={options as any}
          formatOptionLabel={handleFormat as any}
          onChange={handleChange as any}
          placeholder={intl.formatMessage({
            id: 'components.mobileNumber.areaCode',
          })}
          styles={phoneSelectStyles}
        />
      </SelectContainer>
      <Input
        aria-label={`Type Phone Number`}
        autoFocus
        disabled={!selectValue}
        valid={valid}
        type='tel'
        status={valid ? 'success' : 'error'}
        onChange={handleInputChange}
        value={number}
        {...props}
      />
      {showError && <ErrorMessage type='mobileNumber'/>}
    </Container>
  ) : (
    <ReadOnly value={value} fieldName={name} />
  )
}

export { MobileNumber }
export default MobileNumber
