import React, { useContext, useEffect } from 'react'
import Newsletter from './Newsletter'
import useSWR from 'swr'
import { apiService } from '../../service/api/apiService'
import { TopLoadingBarContext } from '../../utils/ContextWrapper'
import { NewslettersWrapper } from './styles'

const Newsletters = () => {
  const { data, error } = useSWR('newsletters', (key: string) =>
    apiService.newsletters.getMany(),
  )
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)

  useEffect(() => {
    if (!data) {
      setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
    } else if (data || error) {
      setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
    }
  }, [data, error, setTopLoadingBar])

  return data ? (
    <NewslettersWrapper>
      {data.data.map((newsletter) => (
        <Newsletter key={newsletter.id} newsletter={newsletter} />
      ))}
    </NewslettersWrapper>
  ) : null
}

export { Newsletters }
