export enum TRAVERSE {
  FORWARD = 'forward',
  REVERSE = 'reverse',
}

export const excludedOrganisationFieldsForUpdate = [
  'members',
  'pendingRequests',
  'pendingInvitations'
]
