import Cookies from 'js-cookie'
import { useCallback, useState } from 'react'
import { TOKEN } from "../service/localStorage/constants";
import { SESSION_TOKEN_KEY } from "./UserAuthClass";

export const defaultCookieOptions = {
  domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
  expires: 60,
}

export const setCookieItem = (key: string, item: any) =>
  Cookies.set(key, item, defaultCookieOptions)

export const getCookieItem = (key: string) => Cookies.get(key)

export function useCookie<TValue = string>(
  key: string,
  defaultValue?: TValue,
): [TValue, (value: TValue) => void] {
  const getCookie = () => (getCookieItem(key) || defaultValue) as TValue
  const [cookie, setCookie] = useState(getCookie())

  const updateCookie = useCallback(
    (value: any) => {
      setCookie(value)
      setCookieItem(key, value)
    },
    [key, setCookie],
  )

  return [cookie, updateCookie]
}

export const setLoggedInCookie = () => setCookieItem('fid-is-loggedin', 'true')
export const setLanguageCookie = (code: string) =>
  setCookieItem('dff-lang', code)

export const getLanguageCookie = () => getCookieItem('dff-lang')

export const getTokenCookie = (): string | undefined =>
  getCookieItem(TOKEN)

export const deleteTokenCookie = () => Cookies.remove(TOKEN)
export const deleteSessionCookie = () => Cookies.remove(SESSION_TOKEN_KEY)

