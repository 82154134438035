import {
  deleteUser,
  getUser,
  updateUser,
  getOrganisations,
  getOtp,
  getQr,
  validateTwoFactorQr,
  uploadPhoto,
  checkEmail,
  checkPassword,
  disableTfa,
  validateSession,
  validatePassword,
  changeEmail,
  changeEmailRequest,
  pendingRequests,
  removePendingRequest,
} from './user'
import {
  authorize,
  getMandatoryProfileFields,
  submitBackupCode,
  resetPassword,
  login,
  register,
  logout,
  sendResetPasswordEmail,
  updatePassword,
  verifyEmail,
  verifyCode,
  resendVerifyEmail,
} from './authorize'
import {
  getOrganisation,
  getOrganisationAdmin,
  getOrganisationAdmins,
  createOrganisation,
  deleteOrganisation,
  updateOrganisation,
  addOrganisationAdmin,
  addOrganisationMember,
  removeOrganisationMember,
  removeOrganisationAdmin,
  addOrganisationLogo,
  joinRequest,
  decideRequest,
  names,
  invite,
  deleteInvitation,
} from './organization'
import { getCities, getNationalities } from './location'
import {
  getNewsletters,
  getNewsletter,
  subscribeNewsletter,
  unsubscribeNewsletter,
} from './newsletter'
import { getTags } from './tags'
import { getCountries } from './countries'
import { decodeUrl, inviteMember } from './invite'

export const apiService = {
  auth: {
    login,
    register,
    verifyEmail,
    verifyCode,
    authorize,
    submitBackupCode,
    resetPassword,
    sendResetPasswordEmail,
    resendVerifyEmail,
    updatePassword,
    checkEmail,
    checkPassword,
    logout,
    getMandatoryProfileFields,
  },
  organisation: {
    get: getOrganisation,
    getAdmin: getOrganisationAdmin,
    getAdmins: getOrganisationAdmins,
    addMember: addOrganisationMember,
    addAdmin: addOrganisationAdmin,
    removeMember: removeOrganisationMember,
    removeAdmin: removeOrganisationAdmin,
    create: createOrganisation,
    delete: deleteOrganisation,
    update: updateOrganisation,
    addLogo: addOrganisationLogo,
    joinRequest,
    decideRequest,
    names,
    invite,
    deleteInvitation,
  },
  user: {
    validate: validateSession,
    validatePassword: validatePassword,
    get: getUser,
    organisations: getOrganisations,
    update: updateUser,
    uploadPhoto: uploadPhoto,
    delete: deleteUser,
    getOtp,
    getQr,
    validateTwoFactorQr,
    disableTfa,
    changeEmail,
    changeEmailRequest,
    pendingRequests,
    removePendingRequest,
  },
  countries: {
    get: getCountries,
  },
  location: {
    getCities,
    getNationalities,
  },
  newsletters: {
    getOne: getNewsletter,
    getMany: getNewsletters,
    subscribe: subscribeNewsletter,
    unsubscribe: unsubscribeNewsletter,
  },
  tags: {
    get: getTags,
  },
  invite: {
    decodeUrl,
    inviteMember,
  },
}
