import { apiService } from "../../service/api/apiService";
import Cookies from "js-cookie";
import { defaultCookieOptions, deleteSessionCookie } from "../../utils/cookies";
import { SESSION_TOKEN_KEY } from "../../utils/UserAuthClass";


export const getProgramsSessionToken = async () => {
  return apiService.auth.authorize('programs', '')
    .then((url: string) => {
      const urlObject = new URL(url);
      const params = new URLSearchParams(urlObject.search)
      const token = params.get(SESSION_TOKEN_KEY)
      if (token) {
        return Cookies.set(SESSION_TOKEN_KEY, token, defaultCookieOptions)
      } else {
        deleteSessionCookie()
      }
    })
    .catch((e) => console.error('Session token is unavailable'))
}
