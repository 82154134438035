import React, { useContext, useEffect } from 'react'
import Profile from './Profile/Profile'
import Organisations from './Organisations/Organisations'
import { SectionHeader } from '../../components'
import { TabContext } from '../../utils/ContextWrapper'
import SettingsMenu from '../settings/SettingsMenu/SettingsMenu'
import { useScrollPosition } from '../../utils/hooks/useScrollPosition'
import { useIntl } from 'react-intl'
import { TutorialSteps } from '../../components/Tutorial/tutorialTypes'
import { tabs } from '../../components/AppHeader/constants'
import {
  OuterContainer,
  ProfileSection,
  SettingsSection,
} from './styles'
import { DashboardProps } from './types'

const Dashboard = ({ isWelcome }: DashboardProps) => {
  const intl = useIntl()
  const rtl = intl.locale === 'ar'
  // TODO: Temporarily hidden until finalize future-id-plugin and actualise the tutorial
  // const { isAuthorizeFlow } = useAuthorize()
  // const [run, setRun] = useState((isWelcome && !isAuthorizeFlow) ?? false)
  const [activeTab] = useContext(TabContext)
  const { y } = useScrollPosition()
  const isScrolled = y > 0
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {/*<Tutorial run={run} setRun={setRun} />*/}
      <OuterContainer isNavbarScrolledDown={isScrolled}>
        <ProfileSection rtl={rtl} visible={activeTab === tabs.PROFILE}>
          <SectionHeader
            hideMobile
            title={intl.formatMessage({ id: 'dashboard.profileHeader' })}
            id={TutorialSteps.PROFILE}
          />
          <Profile />

          <Organisations />
        </ProfileSection>
        <SettingsSection visible={activeTab === tabs.SETTINGS}>
          <SettingsMenu />
        </SettingsSection>
      </OuterContainer>
    </div>
  )
}

export default Dashboard
