import { DateType } from "./types";

export const validateBirthDate = (dateArr: DateType[], type?: string) => {
  const isValidValues = dateArr.reduce((prev, {value}) => prev && !!value, true)
  if (type === 'dob' && isValidValues) {
    const [year] = dateArr.map(({value}, index) => index !== 1 ? value : +value - 1)
    const now = new Date();
    const currentYear = now.getFullYear();
    if ((currentYear - (year as number)) < 13 || (currentYear - (year as number)) > 99) {
      return false;
    }
  }
  return isValidValues
}
