import { IProfileObject } from "../../models/IProfileField";

export const getPrevItem = (fields: IProfileObject[], index: number, isRtl: boolean) => {
  if (isRtl) {
    return fields?.length > index + 1 ? fields[index + 1] : null;
  }
  return index > 0 ? fields[index - 1] : null;
};
export const getNextItem = (fields: IProfileObject[], index: number, isRtl: boolean) => {
  if (isRtl) {
    return index > 0 ? fields[index - 1] : null;
  }
  return fields?.length > index + 1 ? fields[index + 1] : null;
};
