import { handleMockApi } from './handleMockApi'
import axios, { AxiosInstance } from 'axios'
import { deleteFromStorage } from '@rehooks/local-storage'
import { setAuthHeader } from './utils'
import { deleteTokenCookie } from "../../utils/cookies";
import UserAuthClass from "../../utils/UserAuthClass";

const createIdentityInstance = (
  isAuth: boolean,
  config?: any,
): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL + '/api/v1',
    timeout: 15000,
    headers: { ...config },
  })
  instance.interceptors.request.use(
    (config) => setAuthHeader(isAuth, config),
    (error) => {
      return Promise.reject(error)
    },
  )
  instance.interceptors.response.use(
    (res) => res,
    (error) => {
      handleMockApi(error.response.status)
      if (error.response && error.response.status === 401) {
        UserAuthClass.setLogoutUserStatus();
      }
      return Promise.reject(error)
    },
  )
  return instance
}

const createAuthInstance = (isAuth: boolean): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_AUTH_URL + '/api/v1',
    timeout: 12000,
  })
  instance.interceptors.request.use(
    (config) => setAuthHeader(isAuth, config),
    (error) => {
      handleMockApi(error.response.status)
      if (error.response?.status === 401) {
        deleteFromStorage('user')
        deleteTokenCookie()
      }
      return Promise.reject(error)
    },
  )
  return instance
}

const createAuthCookieInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_AUTH_URL + '/api/v1',
    timeout: 12000,
  })
  instance.interceptors.request.use(
    (config) => {
      return { ...config, withCredentials: true }
    },

    (error) => {
      handleMockApi(error.response.status)
      return Promise.reject(error)
    },
  )
  return instance
}

export const axiosCookieAuth = createAuthCookieInstance()
export const axiosAuth = createAuthInstance(true)
export const axiosNoAuth = createAuthInstance(false)
export const axiosIdentity = createIdentityInstance(true)
export const axiosNoAuthIdentity = createIdentityInstance(false)
