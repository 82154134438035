import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { apiService } from '../../service/api/apiService'
import { AuthFormView } from '../../components'
import { isEmailValid } from '../../utils/validation'
import { openToast } from '../../components/ToastMessage/ToastService'
import { Email } from '../../components/TextInput/Email'
import { Form, StyledButton, Title } from './styles'
import { decorateUrlWithLanguagePath } from "../../routing/utils";

const { REACT_APP_LOGIN_URL } = process.env

const ResetPasswordPage = () => {
  const intl = useIntl()
  const history = useHistory<{ email?: string }>()
  const [redirect, setRedirect] = useState('')
  const [email, setEmail] = useState(history.location.state?.email || '')

  const handleEmailChange = (val: string, status: boolean) => setEmail(val)

  const handleResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    apiService.auth
      .sendResetPasswordEmail(email)
      .then((res) => {
        openToast({
          message: intl.formatMessage({ id: 'auth.resetPassword.emailSent' }),
          variant: 'success',
        })
        setRedirect(decorateUrlWithLanguagePath(REACT_APP_LOGIN_URL || '/login'))
      })
      .catch(({ response }) => {
        if (response?.status === 404) {
          openToast({
            message: intl
              .formatMessage({ id: 'errors.unusedEmail' })
              .toString(),
            variant: 'failure',
          })
        } else {
          openToast({
            message: intl
              .formatMessage({ id: 'errors.genericFail' })
              .toString(),
            variant: 'failure',
          })
        }
      })
  }

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <AuthFormView>
      <Title>
        <FormattedMessage id='auth.labels.resetLabel' />
      </Title>
      <Form onSubmit={handleResetPassword}>
        <Email
          fluid
          email={email}
          disableDebounce
          placeholder={intl.formatMessage({ id: 'auth.placeholder.email' })}
          handleEmailChange={handleEmailChange}
        />
        <StyledButton disabled={!isEmailValid(email)}>
          <FormattedMessage id='auth.labels.resetLabel' />
        </StyledButton>
      </Form>
    </AuthFormView>
  )
}

export default ResetPasswordPage
