import { AxiosRequestConfig } from 'axios'
import { getTokenCookie } from "../../utils/cookies";

export const setAuthHeader = (
  isAuth: boolean,
  config: AxiosRequestConfig,
): AxiosRequestConfig => {
  let token = getTokenCookie()
  if (token && isAuth) {
    token = token?.startsWith(`"`)
      ? token.substring(1, token.length - 1)
      : token
    config.headers.Authorization = token
  }
  return { ...config }
}
